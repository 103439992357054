import { toUnicode } from "punycode";

/**
 * Shim for trim function in < IE 9
 */
if (!String.prototype.trim) {
  String.prototype.trim = function () {
    return this.replace(/^\s+|\s+$/g, "");
  };
}

/**
 * Move last word to second line if only the caret flow to second line
 */
$.fn.secondLineTitleRightArrow = function () {
  return this.each(function () {
    var $this = $(this),
      $anchor = $this.children("a"),
      $arrow = $this.find(".icon-arrow-right");
    if ($arrow.length) {
      var str = $.trim($this.text()).split(" "),
        lastword = str.pop(),
        newText =
          str.join(" ") +
          ' <span class = "end-word">' +
          lastword +
          '<i class="icon icon-arrow-right"></i></span>';

      if (!$anchor.length) $this.html(newText);
      else $anchor.html(newText);
    }
  });
};

/**
 * ZOMG. Its the filter list Widget and it's massive
 */
$.fn.filterWidget = function () {
  var hideThemAll = function (e) {
    if (
      !$(e.target).closest(".list-filter-widget").length &&
      !$(e.target).closest(".Zebra_DatePicker").length
    ) {
      $(".list-filter-widget .filter").hide();
    }
  };

  $(window).on("click touchstart", hideThemAll);

  return this.each(function () {
    var $widget = $(this),
      $widgetToggle = $widget.siblings(".filter-toggle");

    // Hide widget if there's a toggle visibility button before it
    if ($widgetToggle.length) {
      $widget.css({ display: "none" });

      /**
       * Show/Hide Widget
       */
      $widgetToggle.on("click", function (e) {
        var $this = $(this);

        if ($this.hasClass("expanded")) {
          $widget.slideUp();
          $this.removeClass("expanded");
        } else {
          $widget.slideDown();
          $this.addClass("expanded");
        }

        e.preventDefault();
      });
    }

    /**
     * Convert select to dropdown list from select obj and return DOM object
     */
    var generateDropListFromSelect = function ($select) {
      var $ulEl = $("<ul/>"),
        $liEl = $("<li/>"),
        $anchorEl = $('<a href = "#"/>');

      $select.find("option").each(function () {
        var $option = $(this),
          $newAnchorEl = $anchorEl.clone(),
          $newliEl = $liEl.clone(),
          $tick = $('<i class = "icon icon-tick"/>');

        $newAnchorEl.text($option.text());

        if ($option.index() === 0) {
          $newAnchorEl.append($tick.clone());
        }

        $newAnchorEl.on("click", function (e) {
          $("option", $select).attr("selected", false);
          $option.attr("selected", "selected");
          $select.trigger("change");

          $ulEl.find(".icon-tick").remove();
          $newAnchorEl.prepend($tick.clone());

          e.preventDefault();
        });

        $newliEl.append($newAnchorEl);
        $ulEl.append($newliEl);
      });

      return $ulEl;
    };

    /**
     * Convert select to dropdown list from select obj and return DOM object
     */
    var generateDropListFromCB = function ($checkboxes, $filter) {
      var $ulEl = $("<ul/>"),
        $liEl = $("<li/>"),
        $anchorEl = $('<a href = "#"/>'),
        $filterObj = $filter.parent(),
        $legend = $filter.find("legend"),
        $filterLabel = $filterObj.find(".filter-label");

      $checkboxes.prop("checked", true);
      $filterLabel.text(
        $checkboxes
          .parent()
          .eq(0)
          .text()
      );

      $checkboxes.each(function () {
        var $option = $(this),
          $label = $(this).parent(),
          $newAnchorEl = $anchorEl.clone(),
          $newliEl = $liEl.clone(),
          $tick = $('<i class = "icon icon-tick"/>');

        $newAnchorEl.text($label.text()).append($tick.clone());

        $newAnchorEl.on("click", function (e) {
          if ($option.prop("checked")) {
            if ($newAnchorEl.parent().index() === 0) {
              $checkboxes.prop("checked", false);
              $ulEl.find(".icon-tick").remove();
            } else {
              $option.prop("checked", false);
              $newAnchorEl.find(".icon-tick").remove();

              $ulEl
                .find("li")
                .eq(0)
                .find(".icon-tick")
                .remove();
              $checkboxes.eq(0).prop("checked", false);
            }
          } else {
            if ($newAnchorEl.parent().index() === 0) {
              $checkboxes.prop("checked", true);
              $ulEl.find("a").prepend($tick.clone());
            } else {
              $option.prop("checked", true);
              $newAnchorEl.prepend($tick.clone());
            }
          }

          var selectedNum = $filter.find("input:checked").length;

          // Display different label depending on number of selected
          if (selectedNum === $ulEl.find("li").length) {
            $filterLabel.text(
              $checkboxes
                .eq(0)
                .parent()
                .text()
            );
          } else if (selectedNum === 1) {
            $filterLabel.text($label.text());
          } else {
            $filterLabel.text($legend.text() + " (" + selectedNum + ")");
          }

          e.preventDefault();
        });

        $newliEl.append($newAnchorEl);
        $ulEl.append($newliEl);
      });

      return $ulEl;
    };

    /**
     *  Setup multiple selection(Checkboxes) dropdown | Does not support extra date object
     */
    var multiSelectDropDown = function ($obj) {
      var $this = $obj,
        $filter = $this.find(".filter"),
        $toggle = $this.find(".filter-anchor"),
        $filterLabel = $this.find(".filter-label"),
        $cbEls = $filter.find('input[type = "checkbox"]'),
        $extra = $filter.find(".filter-extra");

      $filter.css({ display: "none" });

      $toggle.on("click", function (e) {
        if ($filter.css("display") === "none") {
          $widget
            .find(".filter")
            .stop()
            .hide();
          $filter.stop().slideDown();
        } else {
          $filter.stop().slideUp();
        }

        e.preventDefault();
      });

      $filter.prepend(generateDropListFromCB($cbEls, $filter));

      $filter.find(".inputs").hide();
    };

    /**
     * Setup single selection(Select) dropdown | Support extra date object
     */
    var singleSelectDropDown = function ($obj) {
      var $this = $obj,
        $filter = $this.find(".filter"),
        $toggle = $this.find(".filter-anchor"),
        $filterLabel = $this.find(".filter-label"),
        $selectEl = $filter.find(".filter-select"),
        $extra = $filter.find(".filter-extra");

      if ($extra.length) {
        $extra.addClass("initial").hide();
      }

      $filter.css({ display: "none" });

      $toggle.on("click", function (e) {
        if ($filter.css("display") === "none") {
          $widget
            .find(".filter")
            .stop()
            .hide();
          $filter.stop().slideDown();
        } else {
          $filter.stop().slideUp();
        }

        e.preventDefault();
      });

      $filter.prepend(generateDropListFromSelect($selectEl));

      $selectEl
        .on("change", function (e) {
          $filterLabel.text($selectEl.find("option[selected]").text());
          if ($extra.length) {
            if ($filterLabel.text() === "Custom Date Range") {
              $extra.stop().slideDown(function () {
                // Only initial datepicker once
                if ($extra.hasClass("initial")) {
                  $extra.find(".date-from").Zebra_DatePicker({
                    direction: true,
                    format: "d M Y",
                    pair: $extra.find(".date-to"),
                    onOpen: function () {
                      if ($(window).width() > 767) {
                        $(".Zebra_DatePicker").css("top", "1018px");
                      }
                    }
                  });

                  $extra.find(".date-to").Zebra_DatePicker({
                    format: "d M Y",
                    direction: 1,
                    onOpen: function () {
                      if ($(window).width() > 767) {
                        $(".Zebra_DatePicker").css("top", "1067px");
                      }
                    }
                  });

                  $extra.removeClass("initial");
                }
              });
            } else {
              $extra.stop().slideUp();
              $filter.stop().slideUp();
            }
          } else {
            $filter.stop().slideUp();
          }
        })
        .trigger("change");

      $selectEl.hide();
    };

    /**
     * behaviour for each filter options
     */
    $widget.find(".filter-option").each(function () {
      if ($(this).hasClass("multi-select")) {
        multiSelectDropDown($(this));
      } else {
        singleSelectDropDown($(this));
      }
    });
  });
};

/**
 * Jump to section links
 */
$.fn.jumpList = function () {
  return this.each(function () {
    var $links = $(this).find("li a");

    $links.each(function () {
      var $this = $(this),
        $target = null;
      var url = $this.attr("href");

      if (url.indexOf("#") >= 0) $target = $(url.substring(url.indexOf("#")));

      $this.on("click", function (e) {
        $("html, body")
          .stop()
          .animate({ scrollTop: $target.offset().top - 30 }, 400);

        $(".back-to-top-mobile .jump-list-links").css({ display: "none" });
        $(".back-to-top-mobile .filter-toggle").removeClass("expanded");

        e.preventDefault();
      });
    });
  });
};

/**
 * JumpList Mobile Dropdown Menu
 */
$.fn.jumpListMobileDropdown = function () {
  return this.each(function () {
    var $this = $(this),
      $ulEl = $this.find("ul"),
      $anchor = $this.find(".jump-list-header");

    $anchor.wrap('<a href = "#" class = "filter-toggle"/>');
    $anchor = $this.find(".filter-toggle");
    $ulEl.css({ display: "none" });

    $anchor.on("click", function (e) {
      if ($ulEl.css("display") === "block") {
        $ulEl.slideUp();
        $anchor.removeClass("expanded");
      } else {
        $ulEl.slideDown();
        $anchor.addClass("expanded");
      }

      e.preventDefault();
    });
  });
};

/**
 * Pretty Select Dropdown
 */
$.fn.prettifySelect = function () {
  return this.each(function () {
    var $select = $(this),
      $label = $('<span class = "prettify-label"/>'),
      $arrow = $('<span class = "icon icon-arrow-dropdown"/>'),
      $wrapper = $('<div class = "prettify-select-wrapper"/>');

    $select
      .wrap($wrapper)
      .before($label)
      .before($arrow)
      .on("change", function (e) {
        $label.text($select.find(":selected").text());
      })
      .trigger("change");
  });
};

/**
 * Admission form
 */

$.fn.setupAdmissionForm = function ($obj) {
  if (!$obj.length) return;

  $obj.each(function () {
    var $admission = $(this);

    $(".btn-register", $admission).click(function (e) {
      var total = 0;
      var child = 0;
      var form_type = $(this).attr("form-type");
      var err_msg = $(this).attr("error-msg");
      $("select option:selected", $admission).each(function () {
        var ticket_type = $(this)
          .parent()
          .attr("ticket-type");
        if (ticket_type.toLocaleLowerCase().indexOf("child") != -1) {
          child += parseInt($(this).val());
        }
        total += parseInt($(this).val());
      });
      if (form_type == "ChildrenParents" || form_type == "ChildrenOnly") {
        if (child <= 0) {
          alert(err_msg);
          return child > 0;
        }
      }
      if (total <= 0) {
        alert(err_msg);
      }
      return total > 0;
    });
  });
};

/**
 * Registration form
 */
$.fn.setupRegistrationForm = function ($obj) {
  if (!$obj.length) return;

  $obj.each(function () {
    var $registrationForm = $(this),
      timeoutInMins = $registrationForm.data("timeoutMins"),
      timeoutInMs = 0;

    let capacityEndpoint = $(this).data("endpoint"),
      currentlyReserving = $(this).data("reserve");

    if (timeoutInMins) {
      timeoutInMs = parseFloat(timeoutInMins) * 60 * 1000;
    } else {
      timeoutInMs = 600000;
    }

    let leaveTimeout = null;

    clearTimeout(leaveTimeout);

    leaveTimeout = setTimeout(function () {
      alert("Your time is up. Redirecting you to the previous page...");
      window.history.back();
    }, timeoutInMs);

    $(".age-datepicker", $registrationForm).each(function () {
      let $this = $(this);
      $this.Zebra_DatePicker({
        direction: false,
        format: "d M Y",
        view: "months",
        onOpen: function () {
          if ($(window).width() > 767) {
            setTimeout(function () {
              $(".Zebra_DatePicker").css("top", "1670px");
            }, 50);
          }
        }
      });
    });

    $("#accompany", $registrationForm).click(function (e) {
      if ($(this).is(":checked")) {
        $(".adultform:first :input", $registrationForm).each(function () {
          var $this = $(this),
            $ctl = $("#" + $this.attr("proptype"), $registrationForm);

          if (!$ctl.length) return;
          $this.val($ctl.val());
        });
      }
    });
  });
};

/**
 * Events Queue
 */
$.fn.setupEventQueue = function ($obj) {
  if (!$obj.length) return;

  $obj.each(function () {
    let $this = $(this),
      capacityEndpoint = $this.data("endpoint"),
      $parentContainer = $this.find('div[data-composer="true"]'),
      $select = $this.find("select"),
      attendees = 0,
      capacityInterval = null; // initialise interval

    if ($select.length) {
      attendees = $select[0].value;
    }

    // call once on load
    retrieveCapacity(capacityEndpoint, $parentContainer, attendees);

    // and every 30 seconds after
    capacityInterval = setInterval(function () {
      retrieveCapacity(capacityEndpoint, $parentContainer, attendees);
    }, 30000);

    // call when user changes value of attendees in select
    // TODO: when more than one <select> element exists
    $select.on("change", function () {
      let attendees = addAllSelectValues($(this));

      retrieveCapacity(capacityEndpoint, $parentContainer, attendees);
    });
  });

  /** helper functions **/
  function addAllSelectValues($ele) {
    let $parent = $ele.parent(),
      $allSelects = $parent.find("select"),
      totalAttendees = 0;

    $allSelects.each(function (idx, each) {
      totalAttendees += parseInt(each.value);
    });

    return totalAttendees;
  }

  function retrieveCapacity(endpoint, $parent, valueToAdd) {
    $.ajax({
      method: "GET",
      url: endpoint,
      dataType: "json",
      success: function (data) {
        displayInformation(
          {
            success: 1,
            capacity: parseInt(data.Capacity),
            occupied: parseInt(data.Occupied),
            currentlyPaying: data.CurrentlyPaying
          },
          $parent
        );

        let vacancy =
          parseInt(data.Capacity) -
          parseInt(data.Occupied) -
          parseInt(data.CurrentlyPaying);

        addTicketCounterListener(vacancy, $parent);
      },
      error: function (error) {
        displayInformation(error, $parent);
      }
    });
  }

  function addTicketCounterListener(vacancy, $parent) {
    let $admissionBtn = $parent.find(".admission .more .btn");

    if (vacancy > 0) {
      $(".admission .select-ticket").on("change", "select", function () {
        var $allSelects = $(".admission .select-ticket select");
        var totalTickets = 0;

        $allSelects.each(function () {
          let ticketNum = parseInt($(this).val(), 10);
          totalTickets += ticketNum;
        });

        if (totalTickets > vacancy) {
          $admissionBtn.removeClass("btn-register").addClass("btn-waitlist");
        } else {
          $admissionBtn.removeClass("btn-waitlist").addClass("btn-register");
        }
      });
    }
  }

  function displayInformation(obj, parent) {
    let { capacity, occupied, currentlyPaying } = obj;

    capacity = parseInt(capacity);
    occupied = parseInt(occupied);
    currentlyPaying = parseInt(currentlyPaying);

    let $btn = parent.find(".admission .btn-register"),
      $admissionBtn = parent.find(".admission .more .btn"),
      $currentlyPaying = $(".admission__currently-paying"),
      $status = $(".admission__status");

    // default button status: enable
    $btn.attr("disabled", false);

    // empty notifcation about how many people are paying at the moment
    $currentlyPaying.text("").hide();
    $status.text("").hide();

    // handle success message
    if (obj.success) {
      //Already full, no vacancy
      if (occupied >= capacity) {
        $admissionBtn.removeClass("btn-register").addClass("btn-waitlist");

        // inform user
        $status
          .text(
            "This event is fully booked. If you proceed, you will be added to the waitlist."
          )
          .fadeIn();
      }

      // //Not full but will be full if the currently paying users submits
      else if (occupied + currentlyPaying >= capacity) {
        $admissionBtn.removeClass("btn-register").addClass("btn-waitlist");

        // inform user
        $status
          .text(
            "This event will be fully booked soon. If you proceed, you will be added to the waitlist."
          )
          .fadeIn();
      }

      if (currentlyPaying) {
        let message = `${currentlyPaying} other user(s) are making payment to this event`;

        $currentlyPaying.text(message).fadeIn();
      }
    } else {
      // show error message for ajax error
    }

    // handle error message
  }
};

/**
* Speaker Corner form
*/
$.fn.setupSpeakerCorner = function ($obj) {

  if (!$obj.length) return;

  var warningDates = [];

  if (typeof window.warningDates !== 'undefined') warningDates = window.warningDates;

  $obj.each(function () {
    var $speakerCorner = $(this),
      sync = true;

    $('.speaker-datepicker', $speakerCorner).each(function () {
      var maxDate = new Date(),
        $this = $(this),
        datepicker;

      maxDate.setFullYear(maxDate.getFullYear() + 1);
      maxDate.setDate(maxDate.getDate() - 1);

      datepicker = $this.Zebra_DatePicker({
        direction: [true, maxDate.format('dd MMM yyyy')],
        container: $speakerCorner,
        format: 'd M Y',
        onSelect: function (d, i, o, el) {

          let todayDate = new Date();

          let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

          let monthIdx = todayDate.getMonth();

          todayDate = `${todayDate.getDate()} ${months[monthIdx]} ${todayDate.getFullYear()}`;

          let $errorContainer = $this.parents('.scfSingleLineGeneralPanel').find('.current-date-validator .scfValidator');

          if (d == todayDate) {
            // show error
            $errorContainer.css({
              'display': 'flex',
              'width': '100%'
            });

          } else {
            $errorContainer.hide();
          }


          if (typeof warningDates === 'undefined') return;
          var found = false;
          var $dateValidationText = $this.parents('.scfSingleLineGeneralPanel').find('.date-validation-text');

          for (var x = 0; x < warningDates.length; x++) {
            var date = warningDates[x].date,
              year = date.slice(0, 4),
              month = date.slice(4, 6),
              day = date.slice(6, 8),
              dateObj = new Date(year, month - 1, day);

            // Wrapper
            if (dateObj.format('dd MMM yyyy') === d) {
              console.log(warningDates[x].text)
              $dateValidationText
                .html(warningDates[x].text)
                .show();
              found = true;
              break;
            }
          }

          if (!found) {
            $dateValidationText.html('').hide();
          }

          if (d !== i.lastVal) {
            $this.change();
          }

        },
        onChange: function (view, elements) {
          if (typeof warningDates === 'undefined') return;

          var calendarHeading = $('.dp_caption').text(),
            yearMonth = calendarHeading.split(',');

          // Match Day
          elements.each(function () {

            for (var x = 0; x < warningDates.length; x++) {
              var date = warningDates[x].date,
                year = date.slice(0, 4),
                month = date.slice(4, 6),
                day = date.slice(6, 8),
                dateObj = new Date(year, month - 1, day);

              if (dateObj.format('yyyy-MM-dd') === $(this).data('date')) {
                $(this)
                  .addClass('date-blocked')
                  .css({ color: '#fda6a6' });
              }
            }
          });
        },
        onOpen: function () {
          /**
          * Update Warning Dates Data
          */
          if (sync) {
            sync = false;
            $.ajax({
              url: '/speakersapi/speakerevent/getregistereddates'
              // url: '/ajax/dates.json'
            }).done(function (e) {
              if (e.dates.length) {
                warningDates = e.dates;
              }

              var zebra = $this.data('Zebra_DatePicker');
              zebra.hide();
              zebra.show();

              sync = true;
            });
          }
        }
      });
    });


    $('#submitEventReferenceId').on('click', function (e) {
      e.preventDefault();

      var evtId = $('#eventCodeTextBox').val(),
        $validator = $('#eventCodeTextBox').next(),
        $dateField = $('.scf-event-date'),
        $startTimeField = $('.scf-event-starttime'),
        $endTimeField = $('.scf-event-endtime'),
        zebra = $dateField.data('Zebra_DatePicker');

      $validator.hide();

      if (RR.SpeakersCorner.isValidToApplyNewEvent()) {
        RR.SpeakersCorner.moveToStep(1);
        return;
      }

      if (!RR.SpeakersCorner.isValidToAddAnotherSpeaker(evtId)) {
        $validator.show();
        return;
      }

      $.ajax({
        //url: '/ajax/eventDetails.json',
        url: '/speakersapi/speakerevent/getEvent',
        data: { eventCode: evtId }
      }).done(function (data) {
        if (!data.error) {

          sync = false;

          var date = data.details.date,
            year = date.slice(0, 4),
            month = date.slice(4, 6),
            day = date.slice(6, 8)
          RR.SpeakersCorner.moveToStep(1);
          month = parseInt(month);

          switch (month) {
            case 1:
              month = 'Jan';
              break;
            case 2:
              month = 'Feb';
              break;
            case 3:
              month = 'Mar';
              break;
            case 4:
              month = 'Apr';
              break;
            case 5:
              month = 'May';
              break;
            case 6:
              month = 'Jun';
              break;
            case 7:
              month = 'Jul';
              break;
            case 8:
              month = 'Aug';
              break;
            case 9:
              month = 'Sep';
              break;
            case 10:
              month = 'Oct';
              break;
            case 11:
              month = 'Nov';
              break;
            case 12:
              month = 'Dec';
              break;
          }

          $dateField.attr('tabindex', -1);
          $dateField.val(day + ' ' + month + ' ' + year);
          $dateField.parents('.scfDatePickerTextPanel').addClass('disabled');
          zebra.destroy();

          $startTimeField.val(data.details.startTime);
          $endTimeField.val(data.details.endTime);

          /**
          * Drop Down Can't be disabled, so we add a mask to prevent it from being selectable
          */
          $startTimeField.attr('tabindex', -1).parent().addClass('disabled');
          $endTimeField.attr('tabindex', -1).parent().addClass('disabled');
        }
      }).fail(function () {
        $validator.show();
      })
    });

    /**
    * Popover for note
    */
    $('[data-toggle="popover"]', $speakerCorner).each(function () {
      var $that = $(this),
        $innerLabel = $("label:last", $that),
        $info,
        $content;

      if (!$that.data('content-id')) {
        $info = $("<i class='icon icon-info-circle'></i>");
        $content = $that.attr('title');
      } else {
        $info = $('<span class = "more-info"><i class="icon icon-info-circle"></i>More Details</span>'),
          $content = $('#' + $that.data('content-id')).html();
      }

      if ($innerLabel.length) {
        $innerLabel.append($info);
      } else {
        $that.append($info);
      }

      $info.on('click', function (e) {
        e.preventDefault();
      })

      $info.popover({
        placement: 'auto',
        html: true,
        content: function () {
          return $content;
        }
      });
    });

    $(document).on('click.popover', function (e) {
      $('[data-toggle="popover"],[data-original-title]').each(function () {
        //the 'is' for buttons that trigger popups
        //the 'has' for icons within a button that triggers a popup
        if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
          (($(this).popover('hide').data('bs.popover') || {}).inState || {}).click = false  // fix for BS 3.3.6
        }
      });
    });

    RR.SpeakersCorner.init();
  });
};

var scfEventDate = $(".scf-event-date");

if (scfEventDate) {
  if ($(window).width() > 767) {
    scfEventDate.on("click", function () {
      setTimeout(function () {
        $(".Zebra_DatePicker").css("top", "1077px");
      }, 150);
    });
  }
}
