'use strict';

// import './vendor/onmediaquery';
import './modules/rr.backTop';
import './modules/rr.carousel';
import './modules/rr.carouselGallery';
import './modules/rr.home';
import './modules/rr.composer';
import './modules/rr.exploreActivities';
import './modules/rr.mainMenu';
//import './modules/rr.mapListing';
import './modules/rr.pagetabs';
import './modules/rr.panoGallery';
import './modules/rr.sideMenu';
import './modules/rr.sitemap';
import './modules/rr.siteSearch';
import './modules/rr.sliderGallery';
import './modules/rr.SpeakersCorner';
import './modules/rr.stickyMenu';
import './modules/rr.tabifier';
//import './modules/greenmap';
//import './modules/greenmap.list';
import './modules/rr.dynamicFeedback';
import './modules/rr.paform';
import './modules/rr.dss-site-footer.js';
import './modules/rr.dss-site-header.js';
import './modules/rr.registration-form.js';