import 'slick-carousel';
import enquire from 'enquire.js';

window.RR = (function (parent, $) {
    'use strict';

    let $siteHeader = $('.dss-site-header'),
        $linksCategory = $('.site-header__category', $siteHeader),
        searchURL = $siteHeader.data('searchURL'),
        $navSearchIcon = $('.site-header__search-icon'),
        $searchBar = $('.site-header__search-bar'),
        $searchForm = $('form.site-header__search-bar', $siteHeader),
        $searchInput = $('.search-bar__input', $siteHeader),
        $announcement = $('.site-header__announcement', $siteHeader),
        $announcementCloseIcon = $('.announcement__close', $announcement),
        $tabletMenuButton = $('.site-header__tablet-menu-button', $siteHeader);

    let announcementSlickSettings = {
        infinite: true,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        dotsClass: 'custom_paging',
        customPaging: function (slider, i) {
            //Counter for paging
            return i + 1 + '/' + slider.slideCount;
        },
        //Arrows will be auto created in the same level as the text
        nextArrow: '<a href="" class="announcement-next announcement-arrow"><i class="icon-arrow-right"></i><span class="visuallyhidden">button to view the next announcement</span></a>',
        prevArrow: '<a href="" class="announcement-prev announcement-arrow"><i class="icon-arrow-left"></i><span class="visuallyhidden">button to view the previous announcement</span></a>'
    };

    function setMobileContentTopMargin(isAnnouncementClose = false) {
        //Calculate height of site menu
        let siteHeaderHeight =
            $('.site-header__tablet-menu-bar').outerHeight() +
            $('.site-header__government-banner').outerHeight();
        if (!isAnnouncementClose) {
            siteHeaderHeight += $('.site-header__announcement').outerHeight();
        }
        //Prevent overlapping due to fixed site header
        $('#main').css('marginTop', `${siteHeaderHeight}px`);
    }

    function toggleArrowIconDirection($arrowIcon) {
        $arrowIcon.toggleClass('icon-arrow-down').toggleClass('icon-arrow-up');
    }

    function resetAllArrowIconsDirection() {
        //For all h1 icons- tier 1 category names
        $('h1 i', $linksCategory)
            .addClass('icon-arrow-down')
            .removeClass('icon-arrow-up');
    }


    function collapseAllCategories() {
        $('ul', $linksCategory).removeClass('tier-2-list--show');
    }


    function deactiveAllCategoryNames() {
        $('h1', $linksCategory).removeClass('category-name--active');
    }

    function resetCategories() {
        collapseAllCategories();
        deactiveAllCategoryNames();
        resetAllArrowIconsDirection();
    }

    function resetTabletMobileCategories() {
        $('ul.tier-2-list--tablet-active', $linksCategory).slideToggle('50');
        $('ul', $linksCategory).removeClass('tier-2-list--tablet-active');
        deactiveAllCategoryNames();
        resetAllArrowIconsDirection();
    }

    function resetAllArrowIconsDirectionLeelTwo() {
        //For all h1 icons- tier 1 category names
        $('h2 i', '.tier-2-list--tablet-active')
            .addClass('icon-arrow-down')
            .removeClass('icon-arrow-up');
    }

    function collapseAllCategoriesLevelTwo() {
        $('ul', '.tier-2-list--tablet-active').removeClass('tier-3-list--show');
    }

    function deactiveAllCategoryNamesLevelTwo() {
        $('h2', '.tier-2-list--tablet-active').removeClass('category-name-level-two--active');
    }
    function resetCategoriesLevelTwo() {
        collapseAllCategoriesLevelTwo();
        deactiveAllCategoryNamesLevelTwo();
        resetAllArrowIconsDirectionLeelTwo();

    }

    function resetTabletMobileCategoriesLevelTwo() {
        $('ul.tier-3-list--tablet-active', '').slideToggle('50');
        $('ul', '.tier-2-list--tablet-active').removeClass('tier-3-list--tablet-active');
        deactiveAllCategoryNamesLevelTwo();
        resetAllArrowIconsDirectionLeelTwo();
    }

    var allDevices = function () {
        //Detect enter key press
        $announcementCloseIcon.keypress(function triggerCloseAnnouncement(e) {
            if (e.which == 13) {
                //Enter key pressed
                $announcementCloseIcon.click(); //Trigger announcement close button click event
            }
        });

        //Make announcements scrollable
        $('.announcement__content').slick(announcementSlickSettings);

        $('a').hover(function (e) {
            $(this).attr('title', '');
        });

        //Collapse all categories when focus is no longer on any of the categories
        $('.site-header__search-icon, .site-header__logo').focusin(function () {
            resetCategories();
        });

        var isTablet = /Android|ipad/i.test(navigator.userAgent.toLowerCase());

        if (isTablet) {
            //In desktop style, allow level2 list to open when clicking on level1 instead of redirecting
            //$('.site-header__category>h1>a').removeAttr('href');
        }

        //Detect enter key on devices and trigger search form submit
        var isDevice = /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i.test(navigator.userAgent.toLowerCase());

        if (isDevice) {
            $('.search-bar__input').on('keyup', function (e) {
                e.stopPropagation();
                if (e.which == 13) {
                    $('.search-bar__button').click();
                }
            });

            $linksCategory.each(function () {
                if ($(this).children('ul').length == 0) {
                    $('i', this).hide();
                }
            });
            $('.site-header__nav-bar .site-header__links li ul li').each(function () {
                var levelTwoULItem = $(this).next();
                if (!(levelTwoULItem.length > 0 && levelTwoULItem[0].nodeName.toLowerCase() == "ul")) {
                    if ($(this).children('h2').length > 0) {
                        var iconChildHide = $(this).children('h2').children('i');
                        if (iconChildHide.length > 0) {
                            iconChildHide.remove();
                        }
                    }
                }
            });

            $(".dss-site-header .site-header__category ul li i").show();
        }
        else {
            $('.site-header__links .site-header__category ul ul').hide();
            $(".dss-site-header .site-header__category ul li i").hide();
        }
    };

    var desktop = function () {
        /** functions in desktop **/
        enquire.register('screen and (min-width:1023px)', {
            // OPTIONAL
            // If supplied, triggered when a media query matches.
            match: function () {
                //Close announcement bar when close button is clicked
                $announcementCloseIcon.click(function closeAnnouncement() {
                    $announcement.addClass('announcement--close');
                    $announcement.hide();
                });
                //Controls expand/collapse of search bar
                $navSearchIcon.click(function toggleSearchBar() {
                    //Change icon from search to cross when search bar is expanding
                    //Cross to Search when collapsing
                    $('i', this)
                        .toggleClass('icon-search')
                        .toggleClass('icon-cross');

                    //Expand/collapse search bar
                    $searchBar.slideToggle(150);
                });
                //Detect enter key press
                $navSearchIcon.keypress(function triggerToggleSearchBar(e) {
                    if (e.which == 13) {
                        //Enter key pressed
                        $(this).click(); //Trigger search button click event
                    }
                });
                //Expand category list when category is moused over
                $linksCategory.mouseover(function expandCategory() {
                    $('ul', this).addClass('tier-2-list--show');

                    let $tier1CategoryName = $('h1', this);
                    $tier1CategoryName.addClass('category-name--active');
                });

                //Collapse category list when mouse leaves category
                $linksCategory.mouseleave(function collapseCategory() {
                    $('ul', this).removeClass('tier-2-list--show');

                    let $tier1CategoryName = $('h1', this);
                    $tier1CategoryName.removeClass('category-name--active');
                });
            },

            // OPTIONAL
            // If supplied, triggered when the media query transitions
            // *from a matched state to an unmatched state*.
            unmatch: function () { },
            // OPTIONAL
            // If supplied, triggered once, when the handler is registered.
            setup: function () { },
            // OPTIONAL, defaults to false
            // If set to true, defers execution of the setup function
            // until the first time the media query is matched
            deferSetup: true,
            // OPTIONAL
            // If supplied, triggered when handler is unregistered.
            // Place cleanup code here
            destroy: function () { }
        });
    };



    var tabletAndMobile = function () {
        /** functions in tablet and mobile **/
        enquire.register('screen and (max-width:1023px)', {
            // OPTIONAL
            // If supplied, triggered when a media query matches.
            match: function () {

                //Close announcement bar when close button is clicked
                $announcementCloseIcon.click(function closeAnnouncement() {
                    $announcement.addClass('announcement--close');
                    $announcement.hide();
                    setMobileContentTopMargin(true);
                });

                //Clicking on the menu button will expand/collapse nav bar and search bar
                //And hide/show announcement
                $tabletMenuButton.click(function toggleNavBar() {
                    //If announcement bar has been closed before, don't toggle it open
                    if (
                        !$announcement.hasClass('announcement--close') &&
                        $announcement.is(':visible')
                    ) {

                        $announcement.hide();
                        //Remove slick
                        $('.announcement__content').slick('unslick');

                    } else if (
                        !$announcement.hasClass('announcement--close') &&
                        !$announcement.is(':visible')
                    ) {
                        //Make announcement bar appear later after nav bar completely collapses
                        setTimeout(function () {
                            $announcement.fadeIn();
                            //Reinitialize slick
                            $('.announcement__content').slick(announcementSlickSettings);
                        }, 380);
                    }
                    $searchBar.slideToggle(400);
                    $('.site-header__nav-bar').slideToggle(400);

                    $('.site-header__tablet-menu-button i').toggleClass(
                        'icon-menu icon-cross'
                    );
                });
                //Detect enter key press
                $tabletMenuButton.keypress(function triggerToggleNavBar(e) {
                    if (e.which == 13) {
                        //Enter key pressed
                        $(this).click(); //Trigger announcement close button click event
                    }
                });

                //Expand category list when category is touched
                //if category is already expanded, collapse and reset all
                //$linksCategory.click(function expandCollapseTabletCategory() {
                $("ul.site-header__links li h1 a i.icon-arrow-down").click(function () {
                    if ($('ul', $linksCategory).hasClass('tier-2-list--tablet-active')) {
                        resetTabletMobileCategories();
                    } else {
                        resetTabletMobileCategories();
                        $(this).closest("h1").siblings().addClass('tier-2-list--tablet-active').slideToggle('50');
                        let $tier1CategoryName = $(this).closest("h1")
                        $tier1CategoryName.addClass('category-name--active');
                        $('i', $tier1CategoryName).addClass('icon-arrow-up').removeClass('icon-arrow-down');
                    }

                    return false;
                });
                $linksCategory.focusin(function expandFocusedCategory() {
                    //Reset by collapsing all categories
                    resetCategories();

                    //Expand current category's tier 2 list
                    let $expandableTier2List = $(this).closest("h1").siblings();//$('ul', this);
                    $expandableTier2List.addClass('tier-2-list--show');

                    $(this).closest("h1").addClass('category-name--active');

                    //Point arrow icon in current tier 1 category name upwards
                    toggleArrowIconDirection($('h1 i', this));
                });

                $('li a i', $linksCategory).click(function preventCategoryCollapse(
                    event
                ) {
                    event.stopPropagation();
                });


                $("ul.site-header__links li h2 i.icon-arrow-down").click(function () {
                    let $thirdLevelUL = $(this).closest("li").next('ul');
                    if ($thirdLevelUL.length > 0) {
                        if ($thirdLevelUL.hasClass('tier-3-list--tablet-active')) {
                            $('.tier-2-list--tablet-active ul').removeClass('tier-3-list--show');
                            let $tier2CategoryName = $('h2', this);
                            $tier2CategoryName.removeClass('category-name-level-two--active');
                            $('i', $tier2CategoryName).addClass('icon-arrow-up').removeClass('icon-arrow-down');
                            resetTabletMobileCategoriesLevelTwo();

                        } else {
                            resetTabletMobileCategoriesLevelTwo();
                            $('.tier-2-list--tablet-active ul').addClass('tier-3-list--show');
                            let $tier2CategoryName = $(this).closest("h2");
                            $tier2CategoryName.addClass('category-name-level-two--active');
                            $(this).closest("li").next('ul').addClass('tier-3-list--tablet-active').slideToggle('50');
                            $('i', $tier2CategoryName).addClass('icon-arrow-up').removeClass('icon-arrow-down');
                        }
                    }
                    else {
                        resetTabletMobileCategoriesLevelTwo();
                        let $tier2CategoryName = $(this).closest("h2");
                        $('i', $tier2CategoryName).addClass('icon-arrow-up').removeClass('icon-arrow-down');
                    }

                    return false;
                });

                //Prevent category list from closing when link is clicked
                $('li a i', '.tier-2-list--tablet-active').click(function preventCategoryCollapse(
                    event
                ) {
                    event.stopPropagation();
                });

                setMobileContentTopMargin();
            },

            // OPTIONAL
            // If supplied, triggered when the media query transitions
            // *from a matched state to an unmatched state*.
            unmatch: function () {
                $('#main').css('marginTop', '0');

            }
        });
    };

    allDevices();
    desktop();
    tabletAndMobile();

    parent.dssSiteHeader = {};

    return parent;
})(window.RR || {}, jQuery);