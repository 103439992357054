// Home page Revamp

import slick from 'slick-carousel';
import doT from 'dot';
// import 'social-feed';
import moment from 'moment';
import mCustomScrollbar from 'malihu-custom-scrollbar-plugin';
import '../plugins/jquery-ui';
import enquire from 'enquire.js';

mCustomScrollbar($);
window.doT = doT;
window.moment = moment;

window.RR = (function (parent, $) {
	  let homeSetup = function () {
		  let slickSettings = {
			  slidesToShow: 3,
			  slidesToScroll: 1,
			  arrows: true,
			  responsive: [{
				  breakpoint: 580,
				  settings: {
					  slidesToShow: 1,
					  slidesToScroll: 1
				}
			}
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			],
			  nextArrow: '<button class="carousel-next" type="button"><span class="icon icon-arrow-right"></span><span class="visuallyhidden">Button to view the next items of the carousel</span></button>',
			  prevArrow: '<button class="carousel-prev" type="button"><span class="icon icon-arrow-left"></span><span class="visuallyhidden">Button to view the previous items of the carousel</span></button>'
		};

		// limiting characters in events for mobile and desktop
		  enquire.register('screen and (min-width: 579px)', {
			  match: function () {
				  limitEventsChar();
			},
			  unmatch: function () { }
		});

		  function limitEventsChar() {
			  let allEvents = $('.hp-events__name');

			  $.each(allEvents, function (index, each) {

				  let $each = $(each),
					  text = $each.text().trim();

				  if (text.length > 61) {
					  text = text.substring(0, 58) + '...';
				}

				  $each.text(text);
			});
		}

		// general slideshow
		  if ($('.slick-slideshow').length > 0) {
			  $('.slick-slideshow').slick(slickSettings);
		}

		// parks slideshow
		  if ($('.hp-parks__carousel').length > 0) {
			  let hpParksCarouselSettings = Object.assign({}, slickSettings);
			  hpParksCarouselSettings.slidesToShow = 4;

			  $('.hp-parks__carousel').slick(hpParksCarouselSettings);
		}

		  if ($('.hp-events__list').length > 0) {
			  $('.hp-events__item').matchHeight();

			  $('.hp-events__list.slick-slideshow').on('afterChange', function (event, slick, currentSlide) {
				  $('.hp-events__item').matchHeight();
			});
		}

		// Home search suggestions
		  if ($('#hpsearchBox').length) {
			  let hpSearchApi = $('.hp-search').data('searchApi'),
				  $hpSearchBox = $('#hpsearchBox'),
				  $hpSearchData = $('.hp-search__data');
			  $hpSearchBox
				.autocomplete({
					  source: function (request, response) {
						  $.ajax({
							  url: hpSearchApi,
							  dataType: 'jsonp',
							  data: {
								  q: request.term
							},
							  success: function (data) {
								  response(data);
							}
						});
					},
					  classes: {
						  'ui-autocomplete': 'hp-autocomplete'
					}
				})
				.on('input focus change', function () {
					  if (!$hpSearchBox.val()) {
						  $hpSearchData.fadeIn(1500).addClass('active');
					} else {
						  $hpSearchData.fadeOut().removeClass('active');
					}
				});
			/* Hide Quick Enquiry when click on outside */

			  $(document).on('mouseup', function (e) {
				  if ($hpSearchData.hasClass('active')) {
					  if (!$hpSearchBox.is(e.target) && $hpSearchBox.has(e.target).length === 0) {
						  $hpSearchData.removeClass('active').fadeOut();
					}
				}
			});
		}

		// Social Media
		  let templateHTML = `
                <div class="social-media__post">
                    <a class="social-media__post-content" href={{=it.link}} target="_blank">
                        <div class="social-media__images {{? !it.attachment}}no-image {{?}}">
                            {{=it.attachment}}
                        </div>
                        <div class="social-media__content {{? !it.attachment}}no-image {{?}}">
                            <span class="icon icon-{{=it.social_network}}"></span>
                            <p class="social-media__content-desc"> {{=it.text}} </p>
                            <div class="social-media__post-details">
                                <span class="social-media__publish-tag">#nparksbuzz</span>
                                <span class="social-media__publish-time">{{=it.time_ago}}</span>
                            </div>
                        </div>
                    </a>
                </div>
                `;

		  if ($('.social-media__feeds').length > 0) {
			// Initialise social-feed

			  let updateFeed = function () {
				  var initialQuery = '@nparksbuzz';
				  initialQuery = initialQuery.replace(' ', '');
				  let queryTags = initialQuery.split(',');

				// $('.social-media__feeds').socialfeed({
				// 	// FACEBOOK
				// 	facebook: {
				// 		accounts: queryTags,
				// 		limit: 7,
				// 		access_token: '509850649415103|02ecc4e1a848fc7de8e3f14cb4cdd776'
				// 	},
				//
				// 	// INSTAGRAM
				// 	instagram: {
				// 		accounts: ['&4005569510'],
				// 		limit: 2,
				// 		client_id: '1bd1a596ac2441d08b94935227c02dbc',
				// 		access_token: '4005569510.1677ed0.91aacbd536ac410ca6be444c332a3c9e'
				// 	},
				//
				// 	// GENERAL SETTINGS
				// 	length: 200,
				// 	show_media: true,
				// 	template_html: templateHTML
				// });

			};

			// updateFeed();

			// $(".facebook__container").mCustomScrollbar();
		}

		  let hpsearchBtn = $('#SearchBtn'),
			  hpsearchBox = $('#hpsearchBox');

		  hpsearchBox.keyup(function () {
			  let typedVal = $(this).val();

			  if (typedVal.length > 0) {
				  hpsearchBtn.prop('disabled', false);
			} else {
				  hpsearchBtn.prop('disabled', true);
			}
		});

		  $(window).on('load', function () {

			  hpsearchBtn.prop('disabled', true);

			// init askjamie as hidden and shifted to the right
			// $('.chat_box').find('.chat_textarea, .VAclose').trigger('click');

			// let element = $('.ask_cheryl_minimized_tab').not('.sticky-btn').remove();
			// setTimeout(function() {
			// 	$('.chat_box').addClass('feHidden').css({
			// 		'right': '-1500px'
			// 	});
			// }, 500);

		});

	};

	/**
	 * Export module method
	 */

	  parent.home = {
		  setup: homeSetup
	};

	  return parent;
})(window.RR || {}, jQuery);

jQuery(function () {
	  RR.home.setup();
});