/**
 * RR - Homepage interactive search (Not in use)
 */
window.RR = (function(parent, $) {
    'use strict';

    var $input       = $('.site-search .input-text'),
        $placeholder = $input.siblings('.placeholder'),
        $resultWrapper;

    $.xhrPool = [];

    /**
     * Setup listener for input field
     */
    var setup = function(){
        if(!$input.length) return;

    	$input
    	 	.off()
            .on('focus', function() {
                $placeholder.addClass('visuallyhidden');
            })
            .on('blur', function(){
                if ($input.val() === '') {
                    $placeholder.removeClass('visuallyhidden');
                }
            })
            .on('keyup focus', function(){
                var letterNum = $input.val().length;

                $resultWrapper = $('#searchResultWrapper');

                if(letterNum > 3) {

                    // Create wrapper if not already existing, else show hidden wrapper
                    if(!$resultWrapper.length) {
                        setupWrapper();
                    } else {
                        $resultWrapper.fadeIn();
                    }
                    getQuery();
                } else if(letterNum < 2) {
                    if(typeof $resultWrapper !== 'undefined') {
                        $resultWrapper.fadeOut();
                    }
                }
            });

        $input.val('');

        $('body').on('click', hideResultWrapper);
    };


    /**
     * Hide result on click outside
     */
    var hideResultWrapper = function(e) {
        if(!$(e.target).closest('#searchResultWrapper').length && !$(e.target).hasClass('input-text')) {
             if(typeof $resultWrapper !== 'undefined') {
                $resultWrapper.fadeOut();
            }
        }
    };

    /**
     * Cancel all pending ajax requests
     */
    $.xhrPool.abortAll = function() {
        $(this).each(function(idx, jqXHR) {
            jqXHR.abort();
        });
        $(this).each(function(idx, jqXHR) {
            var index = $.inArray(jqXHR, $.xhrPool);
            if (index > -1) {
                $.xhrPool.splice(index, 1);
            }
        });
    };

    /**
     * Setup result box
     */
    var setupWrapper = function() {
        var $wrapper = $('<div class = "search-result-dropdown" id = "searchResultWrapper"/>'),
            $linkWrapper = $('<div class="links-wrapper"/>'),
            $googleSuggestionBox = $('<div class = "suggestion-box"/>');

        $wrapper
            .append($linkWrapper)
            .append($googleSuggestionBox);

        $input.after($wrapper);

        $resultWrapper = $wrapper;
    };

    /**
     * Retrieve result
     */
    var getQuery = function(){
        $.ajax({
                url: "/ajax/search.json",
                datatype: 'jsonp',
                crossDomain: true,
                beforeSend: function( xhr ) {
                        $.xhrPool.push(xhr);
                        xhr.overrideMimeType( "text/plain; charset=x-user-defined" );
                    }
                })
                .error(function(a , b , c){
                    console.log(a);
                    console.log(b);
                    console.log(c);
                })
                .done(function(data) {
                    var result = JSON.parse(data);

                    // Clear Wrapper of all previous results
                    renderQuery(result);
                });

    };

    /**
     * Render Query
     */
    var renderQuery = function(result) {
        var $pageLinks = $resultWrapper.find('.page-link a'),
            exist  = false;

        // Render page links
        // Do not render each result again if it already exist.
        if($pageLinks.length) {
            $pageLinks.each(function(){
                var $this = $(this);
                exist = false;
                for(var i in result.pages) {
                    if($this.html() === result.pages[i].title) {
                        exist = true;
                    }
                }

                if(!exist) {
                    $this.remove();
                    renderPageLink(result.pages[i]);
                }

            });
        } else {
            for(var i in result.pages) {
                renderPageLink(result.pages[i]);
            }
        }

        // Render Google Suggestions
        $resultWrapper.find('.suggestion-box').empty();

        renderSuggestionsHeader(result.keywords.header);

        for(var x in result.keywords.keys) {
            renderSuggestions(result.keywords.keys[x]);
        }
    };

    /**
     * Render suggestion titles
     */
    var renderSuggestionsHeader = function(val) {
        var $header = $('<div class = "suggestion-header"/>');

        $header.html(val);

        $resultWrapper.find('.suggestion-box').append($header);
    };

    /**
     * Render suggestion titles
     */
    var renderSuggestions = function($obj) {
        var $anchor = $('<a href = "#"/>');

        $anchor
            .text($obj.title)
            .attr('href', '/search-result.php?q='+ $obj.title);

        $resultWrapper.find('.suggestion-box').append($anchor);
    };

    /**
     * Render page suggestions
     */
    var renderPageLink = function($obj) {
        var $div    = $('<div class = "page-link"/>'),
            $anchor = $('<a href = "#"/>');

        $anchor
            .attr('href', $obj.url)
            .text($obj.title);

        $div.append($anchor);

        $resultWrapper.find('.links-wrapper').append($div);
    };

	parent.siteSearch = {
        setup: setup
    };

    return parent;

}(window.RR || {}, jQuery));