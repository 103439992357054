/**
 *  RR - Dynamic Feedback Form
 */

import 'leaflet';
import 'leaflet-editable';
import 'leaflet-draw';
import './rr.onemaputils';
import TypeAhead from 'jquery-typeahead';
import 'timepicker';
import Pikaday from 'pikaday';

window.RR = (function (parent, $) {
  'use strict';
  let $feedbackForm = $('.feedbackForm'),
    mapMarker = null,
    startDropPinMarker,
    captchaContainer = $('#captchaContainer');

  let setup = function () {

    if (!$feedbackForm.length) return;

    //API urls
    const SEARCH_API_URL = 'https://developers.onemap.sg/commonapi/search?returnGeom=Y&getAddrDetails=Y&pageNum=1';

    let $subject = $('#subject', $feedbackForm),
      $subTopic = $('#subTopic', $feedbackForm),
      $step3 = $('.step3', $feedbackForm),
      $step4 = $('.step4', $feedbackForm),
      formDataUrl = $feedbackForm.data('form'),
      subjectMappingUrl = $feedbackForm.data('subjectmapping'),
      subjectData = {},
      commonFields = {},
      formFields = {},
      ednMsgWrapper = $('.educationalMsg-wrapper'),
      textAreaHint = 'Hint: Would you like to share your experience with us? Can you tell us the name of the officer?';

    function returnCaptchaToOrigin() {
      document.getElementById('topCaptchaHolder').appendChild(document.getElementById('captchaContainer'));
      captchaContainer.removeClass('show');
    }

    function validateEmail(email) {
      let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }

    function validateSGPhone(phone) {
      let re = /(6|8|9)\d{7}/g;
      return re.test(phone);
    }

    function validateDate(input) {
      let dateRegex = /^(?=\d)(?:(?:31(?!.(?:0?[2469]|11))|(?:30|29)(?!.0?2)|29(?=.0?2.(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))(?:\x20|$))|(?:2[0-8]|1\d|0?[1-9]))([-.\/])(?:1[012]|0?[1-9])\1(?:1[6-9]|[2-9]\d)?\d\d(?:(?=\x20\d)\x20|$))?(((0?[1-9]|1[012])(:[0-5]\d){0,2}(\x20[AP]M))|([01]\d|2[0-3])(:[0-5]\d){1,2})?$/;
      return dateRegex.test(input);
    }

    function validateTime(input) {
      let timeRegex = /\b((1[0-2]|0?[1-9]):([0-5][0-9])([AaPp][Mm]))/g;
      return timeRegex.test(input);
    }

    function showWarning(elem, message) {
      if (message) {
        elem.siblings('.warning').removeClass('hidden').html(message);
      } else {
        elem.siblings('.warning').removeClass('hidden');
      }
    }

    function hideWarning(elem) {
      elem.siblings('.warning').addClass('hidden');
    }

    function renderLinks(item) {
      if (item.IsUrgent) {
        ednMsgWrapper.addClass('isUrgent');
      } else {
        ednMsgWrapper.removeClass('isUrgent');
      }

      let iLikeToDo = '';
      // if (findSubtopicData.fields) {


      if (item.EducationalMsg) {
        ednMsgWrapper.html(item.EducationalMsg);
      } else {
        ednMsgWrapper.html('');
      }

      // display each "Find Info" text and url from the array provided
      if (item.FindInfo) {
        item.FindInfo.forEach(function (each) {
          iLikeToDo += '<a class="step3__infoUrl" href="' + each.Href + '">' + each.Text + '</a>';
        });
      }

      if (item.ShowFeedbackForm) {
        iLikeToDo += `<p class="step3__submit">${item.EnquiryText}</p>`;
      }

      $('.step3__content', $step3).html(iLikeToDo);

      $step3.css('display', 'block');

      if (!item.FindInfo && !item.ShowFeedbackForm) {
        $step3.css('display', 'none');
      }
    }

    // end of helper functions


    // load default options in Subject
    var loadDefaultOptions = function () {
      $.ajax({
        url: subjectMappingUrl,
        contentType: 'json',
      }).done(function (successData) {
        getAllTopics(successData);
        let subjectHTML = '<option>Please select the relevant subject</option>',
          holder;
        successData.forEach(function (each) {
          holder = `<option value="${each.Subject}">${each.Subject}</option>`;
          subjectHTML += holder;
        });
        $subject.html(subjectHTML);
      }).fail(function (error) {
        console.log(error);
      });
    }();

    $('.feedbackForm').on('keypress', function (e) {
      if (e && e.keyCode == 13 && !$(e.target).is("textarea")) {
        e.preventDefault();
        $('.feedbackForm__submit').trigger('click');
        // document.forms[0].submit();
      }
    });






    // form validation
    $(document).on('click', '.feedbackForm__submit', function (e) {

      let formField = $('.form-field'),
        emailField = $('#email'),
        phoneNumber = $('#phone'),
        firstFileInput = $('#attachment1'),
        fileVal = firstFileInput.val(),
        emptyNotice = $('#emptyNotice'),
        date = $('.date'),
        validEmail = validateEmail(emailField.val()),
        $captchaHolder = $('#captchaHolder'),
        captchaValue = $('input', $captchaHolder).val(),
        validCaptcha = true,
        validPhone = true,
        validFileInput = true,
        formIsValid = true,
        xCoord = $('#xCoord').val(),
        submitBtn = $('.feedbackForm__submit');



      window.recaptcha_callback = function () {
        $captchaHolder.find('.warning').remove();
      }


      if (firstFileInput.hasClass('required')) {
        if (fileVal == '') {
          emptyNotice.addClass('show');
          validFileInput = false;
        } else {
          validFileInput = true;
          emptyNotice.removeClass('show');
        }
      }



      // hide all by default
      $('.warning').addClass('hidden');

      if (grecaptcha.getResponse() == "") {
        validCaptcha = false;
        let $warning = $captchaHolder.find('.warning');
        if ($warning.length < 1) {
          $captchaHolder.find('.column-twothirds').append('<p class="warning">Please check the captcha</p>');
        } else {
          $warning.removeClass('hidden');
        }
      } else {
        validCaptcha = true;
        $captchaHolder.find('.warning').remove();
      }


      formField.each(function () {
        if ($(this).hasClass('required')) {
          if ($(this).val() == "") {
            showWarning($(this), null);
            formIsValid = false;
          } else {


            if ($(this).attr('id') == "phone") {
              validPhone = validateSGPhone(phoneNumber.val());
              if (!validPhone) {

                formIsValid = false;
                showWarning($(this), 'Please enter a valid Singapore phone number');

              } else {
                hideWarning($(this));
              }
            }

            if ($(this).hasClass('date')) {
              let validDate = validateDate($(this).val());
              if (!validDate) {

                formIsValid = false;
                showWarning($(this), 'Please enter a valid date');

              } else {
                hideWarning($(this));
              }
            }

            if ($(this).hasClass('time')) {
              let validTime = validateTime($(this).val());
              if (!validTime) {

                formIsValid = false;
                showWarning($(this), 'Please enter a valid time');

              } else {
                hideWarning($(this));
              }
            }

            if ($(this).attr('id') == "email") {

              if (!validEmail) {

                formIsValid = false;
                showWarning($(this), 'Please enter a valid e-mail address')

              } else {
                hideWarning($(this));
              }
            }

            //validate location field
            if ($(this).attr('id') == "street") {

              if (!xCoord) {
                formIsValid = false;
                showWarning($(this), 'Please choose the location from the suggestion or drop pin on the map.')

              } else {
                hideWarning($(this))
              }
            }
          }

          $(this).blur(function () {
            if ($(this).val() != "") {
              hideWarning($(this))
            } else {
              showWarning($(this))
            }
          })
        }
      });

      // validate the phone number regardless of required class whenever the user entered any value in it
      if (phoneNumber.val().length) {
        let validPhone = validateSGPhone(phoneNumber.val()),
          $warning = phoneNumber.parent().find('.warning');
        if (!validPhone) {
          formIsValid = false;
          if (!$warning.length) {
            phoneNumber.parent().append('<p class="warning">Please enter a valid Singapore phone number</p>');
          } else {
            $warning.removeClass('hidden');
          }
        } else {
          $warning.remove();
        }
      }

      //validate the location field


      if (formIsValid && validEmail && validPhone && validFileInput && validCaptcha) {

        submitBtn.addClass('disabled');
        submitBtn.prop('disabled', true);
        $('form').submit();
      } else {
        e.preventDefault();
      }
    })


    function getAllTopics(data) {
      let allTopics = data;

      $subject.on('change', function () {
        returnCaptchaToOrigin();
        // reset form
        $step3.hide();
        $step4.hide();
        ednMsgWrapper.html('');

        var dataObj = $(this).val();
        processData(allTopics);

        function processData(successData) {
          let returnData = successData,
            subTopicsHTML = '',
            htmlHolder = '';
          // reset value of common fields when user changes select options
          commonFields = {};

          returnData.forEach(function (each) {
            if (each.Subject == dataObj) {
              subjectData = each.SubTopics;
              let subTopicLen = subjectData.length;

              if (subTopicLen > 1) {
                htmlHolder = '<option>Please select relevant sub topic</option>';
                subjectData.forEach(function (item) {
                  subTopicsHTML += '<option value="' + item.SubTopic + '">' + item.SubTopic + '</option>';
                });

              } else {
                let single = subjectData[0];
                subTopicsHTML += '<option value="' + single.SubTopic + '">' + single.SubTopic + '</option>';
                renderLinks(single);
              }
              htmlHolder += subTopicsHTML;
              $subTopic.html(htmlHolder);
            }
          })
        }
      });

      $subTopic.on('change', function (event) {
        //reset form
        returnCaptchaToOrigin();

        $step3.hide();
        $step4.hide();

        var subTopic = $(this).val();

        var findSubtopicData = subjectData.find(function (item) {
          return item.SubTopic == subTopic;
        });

        var iLikeToDo = '';

        if (findSubtopicData) {
          // handle education message color
          if (findSubtopicData.IsUrgent) {
            ednMsgWrapper.addClass('isUrgent')
          } else {
            ednMsgWrapper.removeClass('isUrgent')
          }

          // display each "Find Info" text and url from the array provided
          if (findSubtopicData.FindInfo) {
            findSubtopicData.FindInfo.forEach(function (each) {
              iLikeToDo += '<a class="step3__infoUrl" href="' + each.Href + '">' + each.Text + '</a>';
            })
          }

          // if (findSubtopicData.fields) {
          var submitButtonText = !findSubtopicData.EnquiryText ? 'Submit a Feedback/Enquiry' : findSubtopicData.EnquiryText;
          if (findSubtopicData.ShowFeedbackForm) {
            iLikeToDo += `<p class="step3__submit">${submitButtonText}</p>`;
          }

          if (findSubtopicData.EducationalMsg) {
            ednMsgWrapper.html(findSubtopicData.EducationalMsg);
          } else {
            ednMsgWrapper.html('');
          }


          $step3.css('display', 'block');

          if (!findSubtopicData.FindInfo && !findSubtopicData.ShowFeedbackForm) {
            $step3.css('display', 'none');
          }

          $('.step3__content', $step3).html(iLikeToDo);
        }
      });

      // put step 3 click here, then pass allTopics to this function
      $step3.on('click', '.step3__submit', function (e) {
        e.stopPropagation();
        ednMsgWrapper.html('');

        let subjectVal = $subject.val(),
          subTopicVal = $subTopic.val();

        displayForm(formDataUrl, allTopics, subjectVal, subTopicVal);
      });
    }

    function displayForm(api, everything, subject, subtopic) {
      ednMsgWrapper.html('');
      let data = {
        "subject": subject,
        "subtopic": subtopic
      }

      $.ajax({
        url: api,
        data: data,
        contentType: 'application/json',
        dataType: 'json'
      }).done(function (successData) {

        processForm(successData, everything, subject, subtopic);

      }).fail(function (error) {
        console.log(error);
      });

      function processForm(data, everythingArray, subjectVal, subtopicVal) {
        let formHTML = '';
        let fields = data.fields;

        everythingArray.forEach(function (each) {
          let eachItem = each.SubTopics;
          eachItem.forEach(function (single) {
            if (single.SubTopic == subtopicVal) {
              if (single.CaseDetailsHint) {
                textAreaHint = 'Hint: ' + single.CaseDetailsHint;
              }
            }
          })
        })

        fields.forEach(function (each) {
          let field = renderFormField(each);
          if (field) {
            formHTML += field;
          }
        })

        formHTML += `
          <div class="feedbackForm__row columns required" id="captchaHolder">

          </div>
          <div class="feedbackForm__row columns">
            <div class="column-third"></div>
            <div class="column-twothirds">
              <button type="submit" class="feedbackForm__submit" value="submit">Submit</button>
            </div>
          </div>`;
        $step3.hide();
        $step4.html(formHTML).css('display', 'block');

        let textareaEle = $('textarea.form-field');

        if (textareaEle) {
          textareaEle.attr('placeholder', textAreaHint);
        }

        // timepicker
        $('.time').timepicker();

        var handleFileUpload = function () {
          var fileUploadInputs = $('.fileUpload').find('input'),
            openAnother = $('.openAnother');

          fileUploadInputs.on('change', function (event) {
            var fileSize = this.files[0].size / 1024 / 1024,
              name = this.files[0].name,
              ext = name.split('.').pop().toLowerCase();

            if (fileSize > 3 || ($.inArray(ext, ['gif', 'png', 'jpg', 'jpeg', 'svg']) == -1)) {
              event.preventDefault();
              alert("Please upload only image files that are smaller than 3MB.");
              $(this).val('');
            } else {
              $(this).siblings('.openAnother').addClass('show');
              $(this).addClass('form-field');
              $(this).siblings('.filename').html(`(${name})`).addClass('show');
            }
          });

          openAnother.on('click', function (event) {
            event.preventDefault();
            $(this).parent().next().css('display', 'block');
            $(this).hide();
          })
        }();

        // handle radio default value
        $('#No').prop("checked", "checked");

        if ($('.date', $feedbackForm).length) {
          let picker = new Pikaday({
            field: $('.date', $feedbackForm)[0],
            container: $('.date', $feedbackForm).parent()[0],
            format: 'DD-MM-YYYY'
          });

          let picker2 = new Pikaday({
            field: $('.date', $feedbackForm)[1],
            container: $('.date', $feedbackForm).parent()[1],
            format: 'DD-MM-YYYY'
          });
        }


        let textarea = $('textarea');

        textarea.on('keyup', function (e) {
          var $this = $(e.target),
            count = $('.count'),
            len = $this.val().length,
            MAX_CHAR = $this.data('maxchar');

          if (MAX_CHAR) {
            if (len > MAX_CHAR - 1000) {
              count.addClass('show')

              if (e.which < 0x20 || len < MAX_CHAR) {
                var char = MAX_CHAR - len;
                count.text(len + '/' + MAX_CHAR);
                return;
              }

              if ($this.val().length == MAX_CHAR) {
                e.preventDefault();
                count.text(MAX_CHAR + '/' + MAX_CHAR);
              } else if ($this.val().length > MAX_CHAR) {
                $this.val($this.val().substring(0, MAX_CHAR));
              }
            } else {
              count.removeClass('show')
            }
          }
        });

        renderLocationMap();
        // move captcha container to within form
        document.getElementById('captchaHolder').appendChild(document.getElementById('captchaContainer'));
        // then show captcha container
        captchaContainer.addClass('show');
        grecaptcha.reset();

      }
    }

    /* function to render form fields */
    function renderFormField(field) {
      let label = field.fieldLabel,
        name = field.fieldName,
        type = field.fieldType,
        educationalMsg = field.educationalMsg,
        required = field.required;

      if ((type === 'text') || (type === 'email') || (type === 'number')) {
        let requiredHTML = '';
        if (field.required) {
          requiredHTML = '<p class="warning hidden">This input is required!</p>';
        }
        return `<div class="feedbackForm__row columns ${required}"><div class="column-third fieldLabel"><label for="${name}">${label}</label></div>
          <div class="column-twothirds"><input class="form-field ${required || ''}" type="${type}" name="${name}" id="${name}" ${required}/>${requiredHTML}</div></div>`;
      } else if (type === "select") {
        let selectHTML = `<select class="form-field" name="${name}" id="${name}"  ${required}>`;
        field.options.forEach(option => selectHTML += `<option value="${option}">${option}</option>`)
        return `<div class="feedbackForm__row columns ${required}"><div class="column-third fieldLabel"><label for="${name}">${label}</label></div>
          <div class="column-twothirds">${selectHTML}</select></div></div>`;
      } else if (type === "textarea") {
        let limit = field.limit,
          requiredHTML = '';

        if (field.required) {
          requiredHTML = '<p class="warning hidden">This input is required!</p>';
        }
        return `<div class="feedbackForm__row columns"><div class="column-third fieldLabel ${required}"><label for="${name}">${label}</label></div>
          <div class="column-twothirds"><textarea class="form-field ${required || ''}" name="${name}" id="${name}" data-maxchar="${limit}" required /></textarea>
          <span class="count">0/${limit}</span>
          ${requiredHTML}
          </div></div>`;
      } else if (type === "radio") {
        let radioHTML = '<div class="radio-toolbar">';
        field.options.forEach(option => radioHTML += `<input type="radio" value="${option}" name="${name}" id="${option}"  ${required}> <label for="${option}"> ${option}</label>`)
        return `<div class="feedbackForm__row columns"><div class="column-third fieldLabel">${label}</div>
          <div class="column-twothirds">${radioHTML}</div></div></div>`;
      } else if (type === 'file') {
        var count = 1;

        var fileInputHTML = `<div class="feedbackForm__row columns ${required || ''}"><div class="column-third fieldLabel"><label>${label}</label></div><div class="column-twothirds fileUpload">`;

        var advice;
        if (field.advice) {
          advice = field.advice;
          fileInputHTML += `<span class="advice">${advice}</span>`
        }

        while (count <= 3) {
          if (count == 3) {
            fileInputHTML += `
              <label class="fileUploadWrap">
              <input type="file" name="${name}" id="${name+count}" class="${required || ''}"/>
              <span>
              <img src="../Cwp/assets/nparks/images/image-upload.svg">
              </span>
              <span class="filename"></span>
              </label>`;
          } else {
            fileInputHTML += `
              <label class="fileUploadWrap">
              <input type="file" name="${name}" id="${name+count}" class="${required || ''}"/>
              <span>
              <img src="../Cwp/assets/nparks/images/image-upload.svg">
              </span>
              <span class="filename"></span>
              <p class="fileSize">Max file size is 3MB</p>
              <p class="openAnother">Upload another file</p>
              </label>`;
          }

          count++;
        }
        fileInputHTML += '<p id="emptyNotice">Please upload at least one file.</p></div></div>';
        return fileInputHTML;
      } else if (type === 'map') {
        let mapField = 'Feedback Location',
          requiredHTML = '';
        if (field.fieldLabel) {
          mapField = field.fieldLabel
        }
        if (field.required) {
          requiredHTML = '<p class="warning hidden">This input is required!</p>';
        }
        return `<div class="feedbackForm__row columns ${required}"><div class="column-third fieldLabel"><label>${mapField}</label></div>
          <div class="column-twothirds overflowShow">
          <div class="column" id='mapdiv' style='height:300px;'></div>
          <div class="column">
          <div class="typeahead">
          <div class="typeahead__container">
          <div class="typeahead__field">
          <span class="typeahead__query">
          <div class="map-input">
          <label for="street">The location is at/near to:</label>
          <input class="typeahead__autocomplete form-field ${required || ''}" name='street' placeholder='Enter street name or postal code' id="street" autocomplete='off' type='search'/>
          ${requiredHTML}
          <div class="map-options">
          <a href="#" class="js-select-droppin">
          <span class="marker-icon"><i class="icon icon-droppin icon-2x"></i></span>
          Drop pin on map
          </a>
          </div>
          </div>
          </span>
          </div>
          </div>
          </div>
          <label for="street">Any additional information?</label><input type="text" name="addInfo" id="addInfo"  placeholder="Near petrol station, behind lamppost..."/>
          </div>
          </div></div>`;
      } else if (type === 'date') {
        return `<div class="feedbackForm__row columns ${required}"><div class="column-third fieldLabel"><label for="${name}">${label}</label></div>
          <div class="column-twothirds"><input class="form-field date ${required || ''}" type="text" name="${name}" id="${name}" ${required}/><p class="warning hidden">This input is required!</p></div></div>`;
      } else if (type === 'time') {
        return `<div class="feedbackForm__row columns ${required}"><div class="column-third fieldLabel"><label for="${name}">${label}</label></div>
          <div class="column-twothirds"><input class="form-field time ${required || ''}" type="text" name="${name}" id="${name}" ${required}/><p class="warning hidden">This input is required!</p></div></div>`;
      }
    }


    /* function to render map */
    function renderLocationMap() {

      let $mapInput = $('.map-input');

      // Show dropdown for map on focus
      $('input', $mapInput).on('focus', function (e) {
        $mapInput.addClass('showDD');
      });

      // Hide dropdown for map on focus
      $('input', $mapInput).on('input', function (e) {
        $mapInput.removeClass('showDD');
      });


      // Render default map
      let center = L.bounds([1.56073, 104.11475], [1.16, 103.502]).getCenter();
      let map;
      map = L.map('mapdiv', {
        editable: true
      }).setView([center.x, center.y], 10);

      let basemap = L.tileLayer('https://maps-{s}.onemap.sg/v3/Default/{z}/{x}/{y}.png', {
        detectRetina: true,
        maxZoom: 18,
        minZoom: 11
      });

      map.setMaxBounds([
        [1.56073, 104.1147],
        [1.16, 103.502]
      ]);
      basemap.addTo(map);

      // icons
      let defaultIcon = L.icon({
        iconUrl: '/Cwp/assets/nparks/images/markers/end-marker.png',
        iconSize: [20, 29] //Size of the icon image in pixels.
      });

      //global layers and layergroups
      let markersLayer = L.layerGroup().addTo(map);

      function clearLayers() {
        markersLayer.clearLayers();
        $mapInput.removeClass('dropPin');
      }

      function createCaseDetailObj(locations) {

        var chosen = locations[0];
        // var block = "BLK " + chosen.BLOCK;
        var block = '';
        if (chosen.BLOCK) {
          block = `BLK ${chosen.BLOCK}`
        }
        var streetName = '';
        if (chosen.ROAD) {
          streetName = `${chosen.ROAD}`;
        }
        var geoX = `${chosen.XCOORD}`;
        var geoY = `${chosen.YCOORD}`;
        var postalCode = '';
        if (chosen.POSTALCODE) {
          postalCode = `${chosen.POSTALCODE}`;
        }
        var buildingName = '';
        if (chosen.BUILDINGNAME) {
          buildingName = `${chosen.BUILDINGNAME}`;
        }

        $('#buildingName').val(buildingName);
        $('#block').val(block);
        $('#road').val(streetName);
        $('#xCoord').val(geoX);
        $('#yCoord').val(geoY);
        $('#postalCode').val(postalCode);

      }

      var getAddressFromLocation = function (location) {

        var locationDetailAPI = 'https://developers.onemap.sg/privateapi/commonsvc/revgeocode?location={location}&token=';
        var location_params = '&buffer=200&addressType=all&otherFeatures=Y';
        var API_KEY = $('#onemapAPIToken').data('apiToken');
        // var API_KEY = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjQ0MiwidXNlcl9pZCI6NDQyLCJlbWFpbCI6IndhbmdfaHNpbl9wZWlAbnBhcmtzLmdvdi5zZyIsImZvcmV2ZXIiOmZhbHNlLCJpc3MiOiJodHRwOlwvXC9vbTIuZGZlLm9uZW1hcC5zZ1wvYXBpXC92MlwvdXNlclwvc2Vzc2lvbiIsImlhdCI6MTUyMDg4MDkzNCwiZXhwIjoxNTIxMzEyOTM0LCJuYmYiOjE1MjA4ODA5MzQsImp0aSI6IjdjMTAyNTk5MTA2NGUxMTI2NzY0ZmRjOWE4NWQyYWZmIn0.FFQpoEG_BacDEQH9aKS-mcJiIXRCLBU9p7tRjw7FoeQ';

        var locationURL = locationDetailAPI.replace('{location}', location) + API_KEY + location_params;

        $.getJSON(locationURL, function () {}).done(function (data) {
          var block, road, lat, lng, address;

          createCaseDetailObj(data.GeocodeInfo);

          if (data.error) {
            alert(data.error);
          } else {

            if (data.GeocodeInfo[0] !== undefined) {
              if (typeof data.GeocodeInfo[0].BLOCK !== "undefined") {
                block = data.GeocodeInfo[0].BLOCK;
              }

              if (data.GeocodeInfo[0].ROAD) {
                road = data.GeocodeInfo[0].ROAD;
              }

              if (data.GeocodeInfo[0].LATITUDE) {
                lat = data.GeocodeInfo[0].LATITUDE;
              }

              if (data.GeocodeInfo[0].LONGITUDE) {
                lng = data.GeocodeInfo[0].LONGITUDE;
              }

              if (typeof block !== "undefined") {
                address = block + " " + road;
              } else {
                address = road;
              }

              let locationObj = {
                address: address,
                building: data.GeocodeInfo[0].BUILDINGNAME,
                lat: lat,
                lng: lng
              }

              $('input', $mapInput).val(locationObj.address);

            } else {
              alert("No address information found.");
            }
          }

        }).fail(function (err) {
          alert("The routing services provided by OneMap is currently unavailable. Please try again later.");
        });
      };

      function addMarker(position, tooltip) {
        let marker = new L.Marker(position, {
            icon: defaultIcon, //defaultIcon is a L.icon. It can also be a L.divIcon(defaultDivIcon).
            bounceOnAdd: false,
            title: tooltip
          })
          .addTo(markersLayer);

        return marker;
      }


      function showPosition(position, popUpContent) { //position is a L.latLng object
        clearLayers();
        let marker = addMarker(position, popUpContent);
        // map.panTo(position);
        map.flyTo(position, 16, {
          duration: 0.25
        })
      }


      // Typeahead for autocomplete and location search
      let $dropdown = $('.typeahead__autocomplete', $step4),
        $dropPin = $('.js-select-droppin'),
        $mapOptionsParent = $('.map-input'),
        startMarker = null;

      $dropdown.on('blur', function () {
        setTimeout(function () {
          $mapOptionsParent.removeClass('showDD');
        }, 100);
      })

      $dropdown.on('keypress', function () {
        $mapOptionsParent.removeClass('showDD');
      })

      $dropdown.typeahead({
        input: '.typeahead__autocomplete',
        minLength: 2,
        order: "asc",
        dynamic: true,
        display: ['ADDRESS'],
        /*
        TODO: http://www.runningcoder.org/jquerytypeahead/documentation/
        SEARCHVAL, ADDRESS, BLK_NO, ROAD_NAME, BUILDING, POSTAL
        might need to combine these as separate data sources for typeahead
        to enhance relevance of matches.
        */
        source: {
          results: {
            ajax: {
              url: SEARCH_API_URL,
              data: {
                searchVal: '{{query}}'
              },
              path: 'results'
            }
          }
        },
        selector: {
          /*
          These are plugin specific hooks. You may used them, but need to override default rules and create
          your own based on the selectors that you will change.
          TO DO: create custom hooks and css so the plugin usage is not exposed to public.
          */
          container: "typeahead__container",
          result: "typeahead__result",
          list: "typeahead__list",
          group: "typeahead__group",
          item: "typeahead__item",
          empty: "typeahead__empty",
          display: "typeahead__display",
          query: "typeahead__query",
          filter: "typeahead__filter",
          filterButton: "typeahead__filter-button",
          dropdown: "typeahead__dropdown",
          dropdownItem: "typeahead__dropdown-item",
          button: "typeahead__button",
          backdrop: "typeahead__backdrop",
          hint: "typeahead__hint",
          cancelButton: "typeahead__cancel-button"
        },
        debug: false,
        callback: {
          onClickAfter: function (node, a, item, event) {
            if (item) {
              clearLayers();
              let position = L.latLng(item.LATITUDE, item.LONGITUDE);
              showPosition(position, item.ADDRESS);

              //update the hidden input fields for block no, building name and others
              var block = '';

              if (item.BLK_NO) {
                block = `BLK ${item.BLK_NO}`
              }
              var streetName = '';
              if (item.ROAD_NAME) {
                streetName = `${item.ROAD_NAME}`;
              }
              var geoX = `${item.X}`;
              var geoY = `${item.Y}`;
              var postalCode = '';
              if (item.POSTAL) {
                postalCode = `${item.POSTAL}`;
              }
              var buildingName = '';
              if (item.BUILDING) {
                buildingName = `${item.BUILDING}`;
              }

              $('#buildingName').val(buildingName);
              $('#block').val(block);
              $('#road').val(streetName);
              $('#xCoord').val(geoX);
              $('#yCoord').val(geoY);
              $('#postalCode').val(postalCode);
            }
            $mapInput.removeClass('showDD');
          },
          onCancel: function (node, event) {
            clearLayers();
            $('#buildingName').val('');
            $('#block').val('');
            $('#road').val('');
            $('#xCoord').val('');
            $('#yCoord').val('');
            $('#postalCode').val('');
          }
        },
      });


      // function to create lat and lng
      let createLatLng = (location) => {
        return location.lat + "," + location.lng;
      }


      // function for dropping pin on map
        $dropPin.on('click mouseenter', function (e) {
        $('.leaflet-container').addClass('droppin-cursor');

        $mapInput.removeClass('showDD').addClass('dropPin');

        let droppin = L.icon({
          iconUrl: '/Cwp/assets/nparks/images/markers/end-marker.png',
          iconSize: [25, 41]
        });

        let marker = map.editTools.startMarker(null, {
          icon: droppin
        });

        clearLayers();
        marker.addTo(markersLayer);
        // markersLayer.addTo(map);

        marker.on('moveend', function (e) {
          let location = createLatLng(e.target.getLatLng());
          getAddressFromLocation(location);

          let latLongLocation = location.split(',');
          let latLong = L.latLng(latLongLocation[0], latLongLocation[1]);

          map.flyTo(latLong, 16, {
            duration: 0.25
          });
        });
      });

      map.on('editable:drawing:end', function (e) {
        let location = createLatLng(e.layer.getLatLng());
        getAddressFromLocation(location);
        let latLongLocation = location.split(',');
        let latLong = L.latLng(latLongLocation[0], latLongLocation[1]);

        map.flyTo(latLong, 16, {
          duration: 0.25
        });

        $('.leaflet-container').removeClass('droppin-cursor');
      });
    }
  }

  /**
   * Export module method
   */
  parent.dynamicFeedback = {
    setup: setup
  };

  return parent;

}(window.RR || {}, jQuery));

jQuery(function () {
  RR.dynamicFeedback.setup();
});