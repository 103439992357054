/**
 *  RR - Slider Gallery
 */
window.RR = (function(parent, $) {
    'use strict';

    var setup = function($objs){
        if(!$objs.length) return;

        $objs.each(function(){
            create($(this));
        });
    };

    /**
     * Create gallery
     */
    var create = function($obj){
        var $wrapper     = $obj,
            $gallery     = $wrapper.children('.gallery'),
            $slides      = $wrapper.find($('.banner-image')),
            $rightAnchor = $('<a href = "#" class = "btn-right"><i class="icon icon-arrow-right"></i></a>'),
            $leftAnchor  = $('<a href = "#" class = "btn-left"><i class="icon icon-arrow-left"></i></a>'),
            currSlide    = 0;


        $gallery.width($slides.length * $wrapper.width());

        $slides.each(function(){
            loadImg($(this));
        });

        $(window).on('resize', function(){
            $gallery.width($slides.length * $wrapper.width());
            $slides.each(function(){
                var $this = $(this);

                $this.width($wrapper.width());
            });

        }).trigger('resize');

        // Right Arrow
        $rightAnchor.on('click', function(e) {

            currSlide++;

            if(currSlide > $slides.length -1) {
                currSlide =  $slides.length -1;
            }

            $gallery.stop().animate({'left' : currSlide * $wrapper.width() *-1});

            e.preventDefault();
        });

        // Left Arrow
        $leftAnchor.on('click', function(e) {

            currSlide--;

            if(currSlide < 0) {
                currSlide =  0;
            }

            $gallery.stop().animate({'left' : currSlide * $wrapper.width() *-1});

            e.preventDefault();
        });

        if($slides.length > 1) {
            $wrapper
                .append($rightAnchor)
                .append($leftAnchor);
        }

        /**
         * Touch input via Hammerjs
         */
        if(Modernizr.touch) {
            var hammertime,
                swipingCounter = false;

            if(typeof hammertime === 'undefined') {
                hammertime = new Hammer($wrapper[0]).on('swipe drag', function(e){

                    if(!swipingCounter){
                        if(e.gesture.direction === 'left'){

                            currSlide +=1;

                            if(currSlide > $slides.length -1) {
                                currSlide =  $slides.length -1;
                                swipingCounter = false;
                            } else {
                                swipingCounter = true;
                            }

                            e.gesture.preventDefault();

                        } else if(e.gesture.direction ==='right') {

                            currSlide -=1;

                            if(currSlide < 0) {
                                currSlide =  0;
                                swipingCounter = false;
                            } else {
                                swipingCounter = true;
                            }

                            e.gesture.preventDefault();
                        }
                        $gallery.stop().animate({'left' : currSlide * $wrapper.width() *-1}, function(){
                            swipingCounter = false;
                        });
                    }
                });
            } else {
                hammertime.enable(true);
            }
        }
    };

    /**
     * Load Image
     */
    var loadImg = function($this) {
        if(!$this.find('img').length) {
            var img = new Image();
            img.onload = function() {
                var $img = $('<img style = "display: none;" alt = ""/>');

                $this.prepend( $img.attr('src', img.src).fadeIn() );
            };
            img.src = $this.attr('data-src');
        }
    };

    /**
     * Export Methods
     */
    parent.sliderGallery = {
        setup: setup
    };

    return parent;
}(window.RR || {}, jQuery));

jQuery(function($){
    RR.sliderGallery.setup($('.slider-gallery'));
});