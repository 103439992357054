/**
*  RR - Back to top
*/
window.RR = (function(parent, $){
  'use strict';

  var desktop = function() {
    if( $('.back-to-top-desktop').length ) return;

    setup('back-to-top-desktop');
  };

  /**
  * Setup back to top link
  */
  var setup = function(className) {
    var $wrapper = $('<div/>'),
      $anchor = $('<a href="#"><i class="icon icon-arrow-up"></i><span class="label">Back To Top</span></a>');

    $wrapper
    .addClass(className)
    .append($anchor);

    $('body').append($wrapper);

    /**
    * Bind Behaviours
    */
    $anchor.on('click', function(e) {
      $('html, body').stop().animate({'scrollTop': 0});
      e.preventDefault();
    })
    .on('mouseover', function(e){
      $wrapper.stop().animate({'right': '0'});
    })
    .on('mouseleave', function(e){
      $wrapper.stop().animate({'right': '-90px' });
    }).trigger('mouseleave');

    var scrolling = function(){
      var scrollTop = $(window).scrollTop();

      if(scrollTop > $(window).height()) {
        $wrapper.fadeIn();
      } else {
        $wrapper.fadeOut();
      }
    };

    /**
    * On Scroll
    */
    $(window)
    .on('scroll.window', scrolling)
    .trigger('scroll.window');
  };

  /**
  * Export module method
  */
  parent.backTop = {
    desktop: desktop
  };

  return parent;

}(window.RR || {}, jQuery));
