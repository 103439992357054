window.RR = (function (parent, $) {

	  var $paform = $('.paform'),
		  $form = $('.paform__form', $paform),
		  $fields = $('.paform-field', $form),
		  $submitSection = $('.paform__submit', $form),
		  $submitBtn = $('.paform__btn-submit', $form),
		  $exampleFields = $('.paform-examples input', $form),
		  $inputFields = $('input, select, textarea', $form),
		  $requiredFields = $('input:required, textarea:required, select:required', $form);


	  var setup = function () {

		  if (!$paform.length) {
			  return false;
		}

		  $requiredFields.each(function () {
			  var $this = $(this),
				  $parent = $this.parents('.paform-field');

			  $parent.addClass('required');
		});

		  $submitBtn.on('click', function (e) {

			  if (!formValidated()) {
				  e.preventDefault();

				  if (!$submitSection.find('.paform-error-msg').length) {
					  $submitSection.prepend('<p class="paform-error-msg"></p>');
				}

				  $submitSection.find('.paform-error-msg').text('Some of the fields are not filled in correctly. Please correct them and try submit again.');

				  return false;
			}
		});

		  $(document).on('keypress', '.paform', e => {
			  if (e.which == 13 || e.keyCode == 13) {
				  $submitBtn.trigger('click');
			}
		});



		// On key up
		$fields.each(function() {
			var $this = $(this);


			if ($this.hasClass('paform-address')) {
				var $floorInput = $this.find('.paform-input--floor'),
				$unitInput = $this.find('.paform-input--unit'),
				$addressInput = $this.find('.paform-input--address'),
				$postalInput = $this.find('.paform-input--postal'),
				$sameAddressInput = $this.find('.paform-input--sameAddress');


				$sameAddressInput.on('change', function() {

					let $addressStreet = $('#addressStreet'),
						$addressFloor = $('#addressFloor'),
						$addressUnit = $('#addressUnit'),
						$postalCode = $('.paform-input--postal');

					let addressStreetVal = $addressStreet.val(),
					addressFloorVal = $addressFloor.val(),
					addressUnitVal = $addressUnit.val(),
					postalCodeVal = $postalCode.val();

					if ($sameAddressInput.prop('checked')) {


						$floorInput.prop('disabled', true).val(addressFloorVal);
						$unitInput.prop('disabled', true).val(addressUnitVal);
						$addressInput.prop('disabled', true).val(addressStreetVal);
						$postalInput.prop('disabled', true).val(postalCodeVal);


						$this.find('.paform-input').removeClass('error');
						$this.find('.paform-error-msg').remove();
					} else {

						$floorInput.prop('disabled', false).val('');
						$unitInput.prop('disabled', false).val('');
						$addressInput.prop('disabled', false).val('');
						$postalInput.prop('disabled', false).val('');

					}
				});

				  $floorInput.on('keyup change focusout', function () {
					  if (!regexValidated($floorInput.val(), $floorInput.attr('pattern'))) {
						  $floorInput.addClass('error');
						  showInvalidMessage($floorInput, 'Floor no. must be numeric value');
					} else {

						$floorInput.removeClass('error');
						$this.find('.paform-error-msg').remove();
					}
				});

				  $unitInput.on('keyup change focusout', function () {
					  if (!regexValidated($unitInput.val(), $unitInput.attr('pattern'))) {
						  $unitInput.addClass('error');
						  showInvalidMessage($unitInput, 'Unit no. must be numeric value');
					} else {
						  $unitInput.removeClass('error');
						  $this.find('.paform-error-msg').remove();
					}
				});

				  $addressInput.on('keyup change focusout', function () {
					  if (!requiredValidated($addressInput.val())) {

						  $addressInput.addClass('error');
						  showInvalidMessage($addressInput, 'Please fill in the address');
					} else {
						  $addressInput.removeClass('error');
						  $this.find('.paform-error-msg').remove();
					}
				});

				  $postalInput.on('keyup change focusout', function () {
					  if (!regexValidated($postalInput.val(), $postalInput.attr('pattern'))) {
						  $postalInput.addClass('error');
						  showInvalidMessage($postalInput, 'Postal code must be 6 digits numeric value');
					} else {
						  $postalInput.removeClass('error');
						  $this.find('.paform-error-msg').remove();
					}
				});
			} else if ($this.hasClass('paform-citizenship')) {
				let citizenshipRadios = $('input[name="contentplaceholder_0$citizenshipList"]');

				citizenshipRadios.on('change', function() {
					
					let $parent = $(this).closest('.paform-citizenship__radios'),
					$errorMsg = $parent.find('.paform-error-msg');
	
	
					if ($errorMsg.length) {
	
						$errorMsg.remove();
	
					}
	
				})
			} else if ($this.hasClass('paform-nric')) {
				var $input = $this.find('input');

				$input.on('keyup change focusout', function() {
					$input.val($input.val().toUpperCase());
					if (!requiredValidated($input.val())) {
						$this.addClass('has-error');
						showInvalidMessage($input, 'Please fill up this field');
					} else if (!nricValidated($input.val())) {
						$this.addClass('has-error');
						showInvalidMessage($input, 'Invalid NRIC format');
					} else {
						$this.removeClass('has-error');
						$this.find('.paform-error-msg').remove();
					}
				});
			} else if ($this.hasClass('paform-examples')) {
				var $input = $this.find('input');

				// 	  $input.on('keyup change focusout', function () {
				// 		  if ($input.val().trim().length > 0) {
				// 			  $input.removeClass('error');
				// 			  $this.find('.paform-error-msg').remove();
				// 		}
				// 	});

			} else if ($this.hasClass('paform-checkbox') && $this.find('input').prop('required')) {
				  var $input = $this.find('input');

				  $input.on('change', function () {
					  if ($input.prop('checked')) {
						  $this.find('.paform-error-msg').remove();
					} else {
						  if (!$this.find('.paform-error-msg').length) {
							  $this.append('<p class="paform-error-msg"></p>');
						}
						  $this.find('.paform-error-msg').text('Please check this field');
					}
				});

			} else {
				  var $input = $this.find('input, textarea, select');

				  if ($input.prop('required')) {
					  $input.on('keyup change focusout', function () {
						  if (!requiredValidated($input.val())) {
							  $this.addClass('has-error');

							  var message = 'Please fill up this field';

							  if ($input.is('select')) {
								  message = 'Please select an option';
							}

							  showInvalidMessage($input, message);
						} else {
							  $this.removeClass('has-error');
							  $this.find('.paform-error-msg').remove();
						}
					});
				}
			}
		});
	};

	  var formValidated = function () {
		  var isValid = true;

		  $fields.each(function () {
			  var $this = $(this);



			  if ($this.hasClass('paform-address')) {
				/* Validation for address:
				- floor and unit must be numeric
				- postal code must be 6 digits numeric
				 */
				  var $floorInput = $this.find('.paform-input--floor'),
					  $unitInput = $this.find('.paform-input--unit'),
					  $addressInput = $this.find('.paform-input--address'),
					  $postalInput = $this.find('.paform-input--postal'),
					  $sameAddressInput = $this.find('.paform-input--sameAddress');

				  if ($sameAddressInput.prop('checked')) {
					  return;
				}

				  if (!requiredValidated($addressInput.val())) {
					  isValid = false;

					  $addressInput.addClass('error');
					  showInvalidMessage($addressInput, 'Please fill in the address');
					  return;
				} else {
					  $addressInput.removeClass('error');
				}

				  if (!regexValidated($floorInput.val(), $floorInput.attr('pattern'))) {
					  isValid = false;
					  $floorInput.addClass('error');
					  showInvalidMessage($floorInput, 'Floor no. must be numeric value');
					  return;
				} else {
					  $floorInput.removeClass('error');
				}

				  if (!regexValidated($unitInput.val(), $unitInput.attr('pattern'))) {
					  isValid = false;

					  $unitInput.addClass('error');
					  showInvalidMessage($unitInput, 'Unit no. must be numeric value');
					  return;
				} else {
					  $unitInput.removeClass('error');
				}

				  if (!regexValidated($postalInput.val(), $postalInput.attr('pattern'))) {
					  isValid = false;

					  $postalInput.addClass('error');
					  showInvalidMessage($postalInput, 'Postal code must be 6 digits numeric value');
					  return;
				} else {
					  $postalInput.removeClass('error');
				}

				$this.find('.paform-error-msg').remove();

			} else if ($this.hasClass('paform-citizenship')) {

				let citizenRadioChecked = $('input[name="contentplaceholder_0$citizenshipList"]:checked').val(),
				parent = $('#contentplaceholder_0_citizenshipList');

				if (!citizenRadioChecked) {
					isValid = false;

					parent.addClass('error');
					showInvalidMessage(parent, 'Please provide your Citizenship Status');
					return;
				} else {

					parent.removeClass('error');

				}
				

			} else if ($this.hasClass('paform-nric')) {
				var $input = $this.find('input');

				if (!requiredValidated($input.val())) {
					isValid = false;
					$this.addClass('has-error');
					showInvalidMessage($input, 'Please fill up this field');
				} else if (!nricValidated($input.val())) {
					isValid = false;
					$this.addClass('has-error');
					showInvalidMessage($input, 'Incorrect NRIC format.');
				} else {
					$this.removeClass('has-error');
					$this.find('.paform-error-msg').remove();
				}

			} else if ($this.hasClass('paform-examples')) {
				/* Validation for examples of plants: at least 1 example to be provided */
				var $inputs = $this.find('input'),
				noOfFilledInput = 0;

				$inputs.each(function() {
					var $input = $(this);

					if ($input.val().trim().length > 0) {
						noOfFilledInput++;
					}
				});

				if (noOfFilledInput == 0) {
					isValid = false;
					if (!$this.find('.paform-error-msg').length) {
						$this.append('<p class="paform-error-msg"></p>');
					}
					$this.find('.paform-error-msg').text('Please fill up at least 1 example above');
					$this.find('input:eq(0)').addClass('error');
				} else {
					$this.find('.paform-error-msg').remove();
					$inputs.removeClass('error');
				}

			} else if ($this.hasClass('paform-checkbox') && $this.find('input').prop('required')) {
				/* Validation for required checkbox */
				  var $input = $this.find('input');

				  if (!$input.prop('checked')) {
					  isValid = false;
					  $this.addClass('has-error');
					  if (!$this.find('.paform-error-msg').length) {
						  $this.append('<p class="paform-error-msg"></p>');
					}
					  $this.find('.paform-error-msg').text('Please check this field');

				} else {
					  $this.removeClass('has-error');
					  $this.find('.paform-error-msg').remove();
				}

			} else if ($this.hasClass('paform-tel')) {
				/* Validation for phone number
					- optional +65 in beginning
					- begin with 6/8/9, followed by 7 digits
					- no space
				*/
				  var $input = $this.find('input');

				  if ($input.val().trim().length > 0 && !phoneValidated($input.val())) {
					  isValid = false;
					  $this.addClass('has-error');

					  showInvalidMessage($input, 'Invalid phone number');
				} else {
					  $this.removeClass('has-error');

					  $this.find('.paform-error-msg').remove();
				}
			} else if ($this.hasClass('paform-email')) {
				/* Validation for email */
				  var $input = $this.find('input');

				  if ($input.val().trim().length > 0 && !emailValidated($input.val())) {
					  isValid = false;
					  $this.addClass('has-error');

					  showInvalidMessage($input, 'Invalid email address');

				} else {
					  $this.removeClass('has-error');

					  $this.find('.paform-error-msg').remove();
				}

			} else if ($this.hasClass('paform-citizenship')) {
				//Validation for citizenship radio buttons
				  var $checkedInput = $('input[type=radio]:checked', $this);

				  if ($checkedInput.length === 0) {
					//No radio buttons is checked
					  isValid = false;
					  $this.addClass('has-error');

					  showInvalidMessage($('.paform-citizenship__radios label', $this).first(), 'Please select an option');

				} else {
					  $this.removeClass('has-error');

					  $this.find('.paform-error-msg').remove();
				}
			}
			else {
				/* Validation for other required fields */
				  var $input = $this.find('input, textarea, select');

				  if ($input.prop('required')) {

					  if (!requiredValidated($input.val())) {
						  isValid = false;
						  $this.addClass('has-error');

						  var message = 'Please fill up this field';

						  if ($input.is('select')) {
							  message = 'Please select an option';
						}

						  showInvalidMessage($input, message);

						  return;

					} else {

						  $this.removeClass('has-error');

						  $this.find('.paform-error-msg').remove();

					}
				}
			}
		});

		  return isValid;
	};

	  var requiredValidated = function (value) {
		  if (value.trim().length == 0) {
			  return false;
		}
		  return true;
	};

	  var regexValidated = function (value, regexStr) {
		  var regex = new RegExp('^' + regexStr + '$');

		  if (!regex.test(value)) {
			  return false;
		}
		  return true;
	};

	  var phoneValidated = function (value) {
		  var regex = /^(\+)?(65)?(6|8|9)\d{7}$/;

		  if (!regex.test(value)) {
			  return false;
		}

		  return true;
	};

	  var emailValidated = function (value) {
		  var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		  if (!regex.test(value)) {
			  return false;
		}

		  return true;
	};

	  var showInvalidMessage = function ($input, message) {
		  if (!$input.parent().find('.paform-error-msg').length) {
			  $input.parent().append('<p class="paform-error-msg"></p>');
		}

		  $input.parent().find('.paform-error-msg').text(message);
	};

	  parent.paform = {
		  setup: setup
	};

	  return parent;

}(window.RR || {}, jQuery));

jQuery(function () {
	  RR.paform.setup();
});