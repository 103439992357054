/**
 * RR - Page Tabs /\/\
   How to Use:
   1. Instantiate it to the wapper of the tabs
   2. Tabs should target the pages via anchor i.e #thepageid
   3. Target pages should have the corresponding id, should be siblings of the tabs wrapper and use class="tabcontent"
 */

window.RR = (function(parent, $) {
    'use strict';

    var $allTabs,
        $mobileToggle,
        currRes = 'desktop';

    var setup = function($tabs){

        if(!$tabs.length) return;

        $allTabs = $tabs;

        $tabs.each(function(){
            create($(this));
        });
    };

    var create = function($obj){
        var $tabAnchors = $obj.find('a'),
            $tabPages=$obj.siblings('.tabcontent');

        $tabAnchors.on('click', function(e){
            e.preventDefault();
            var $this=$(this);

            if($($this.attr('href')).length > 0){ // do this only when a target exists

                $tabAnchors.removeClass('active');
                $this.addClass('active');

                $tabPages.hide();
                $($this.attr('href')).show();

                $obj.find('.filter-toggle').text($this.text());

                if(currRes === "mobile") {
                    $obj.children('ul').slideUp();
                }
            }
        });

        $tabAnchors.eq(0).addClass('active');
        $tabPages.hide();
        $tabPages.eq(0).show();
    };

    var mobile = function() {
        if(typeof $allTabs === "undefined") return;

        currRes = 'mobile';

        $allTabs.each(function(){
            var $this           = $(this),
                $tabAnchors     = $this.find('a'),
                $filterList     = $this.children('ul'),
                $toggle         = $('<a href = "#" class = "filter-toggle">'),
                $toggleLabel    = $('<span class = "filter-label"/>');

            $tabAnchors.each(function(){
                if($(this).hasClass('active')) {
                     $toggleLabel.text($(this).text());
                }

                $(this).append($('<i class="icon icon-tick"/>'));
            });

            $toggle.on('click', function(e){
                if($filterList.css('display') === "block") {
                    $filterList.stop().slideUp();
                    $toggle.removeClass('expanded');
                } else {
                    $filterList.stop().slideDown();
                    $toggle.addClass('expanded');
                }

                e.preventDefault();
            });

            $this
                .prepend(
                    $toggle.append($toggleLabel)
                );

            $mobileToggle = $toggle;

            $filterList.hide();
        });
    };

    var desktop = function(){
        if(typeof $allTabs === "undefined") return;

        currRes = 'desktop';

        $allTabs.each(function(){
            var $this           = $(this),
                $tabAnchors     = $this.find('a'),
                $filterList     = $this.children('ul');

            $this.find('.filter-toggle').remove();
            $tabAnchors.find('.icon').remove();
            $filterList.show();
        });
    };

    parent.pagetabs = {
        setup: setup,
        mobile: mobile,
        desktop: desktop
    };

    return parent;

}(window.RR || {}, jQuery));
