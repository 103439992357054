/**
 * RR - Carousel Gallery
 *
 * Sliding Image gallery
 * Gallery should always has at least 2 images.
 */
window.RR = (function(parent, $) {
    'use strict';

    var $carousel = $('.content-banner .carousel-gallery'),
        $contentBanner = $('.content-banner'),
        $gallery  = $carousel.find('.gallery');

    var setup = function() {
        if(!$carousel.length) return;

        var $slides         = $carousel.find('.banner-image'),
            $win            = $(window),
            $body           = $('body'),
            $rightAnchor    = $('<a href = "#" class = "btn-right"><i class="icon icon-thin-arrow-right"></i></a>'),
            $leftAnchor     = $('<a href = "#" class = "btn-left"><i class="icon icon-thin-arrow-left"></i></a>'),
            $arrowWrapper   = $('<div class = "nav-ui"/>'),
            currSlide       = 0,
            slideDiff       = 0,
            swipingCounter  = false,
            loaded          = 0,
            galleryWidth,
            timeoutID;

        $arrowWrapper
            .append($rightAnchor)
            .append($leftAnchor);

        $carousel.width(0);

        $contentBanner
            .append($arrowWrapper)
            .on('mouseenter', function(){
                $arrowWrapper.css({'opacity' : 1});
            })
            .on('mouseleave', function(){
                $arrowWrapper.css({'opacity' : 0});
            });

        /**
         * Get $slides instances and bind mouse event behaviour
         */
        var initSlides = function() {
            $slides = $carousel.find('.banner-image'); // Get instances of all Images including duplicated

            $slides.each(function(){
                var $this = $(this);

                $this.off().on('click', function(){

                    currSlide = $carousel.find('.focus').index();
                    slideDiff = $this.index() - currSlide;
                    currSlide = $this.index();

                    focusImg(currSlide);
                });
           });
        };

        /**
         * Load image as image appended to $this
         * @param {obj} $this | Element to load image in
         */
        var loadImg = function($this) {
            if(!$this.find('img').length) {
                var img = new Image(),
                    threshold = 2;

                img.onload = function() {
                    var $img = $('<img style = "display: none;" alt = ""/>');

                    $this.prepend( $img.attr('src', img.src).fadeIn() );

                    if($body.width() < 580) {
                        // Mobile
                        $this.width($body.width() - 40);
                    }

                    $carousel.width( $carousel.width() +  img.width);

                    loaded++;

                    // Duplicate and fill up gallery width once all images are loaded
                    if(loaded === $slides.length) {
                        $carousel.parent().css({'background' : '#000000'});
                        $slides = $carousel.find('.banner-image'); //Get instances of loaded Images

                        galleryWidth = $carousel.width();

                        // Make sure that the mutiply of html body width by threshold
                        // is always greater than the carousel width
                        while($carousel.width() > ($('body').width()*threshold)) {
                            threshold++;
                        }

                        while($carousel.width() < ($('body').width()*threshold)){
                            $carousel
                                .children('.gallery')
                                .append( $slides.clone() );
                            $carousel.width($carousel.width() + galleryWidth);
                        }

                        $carousel.find('img').css({'opacity':1});

                        initSlides();

                        focusImg(loaded);
                        currSlide = loaded;

                        // Trigger repaint
                        $carousel.width($carousel.width() + 10);
                    }
                };

                img.src = $this.attr('data-src');
            }
        };


        /**
         * load Images.
         */
        $slides.each(function(){
            var $this = $(this);

            loadImg($this);
        });

        /**
         * Focus selected slide to center of the sliding gallery or left of the desktop UI width
         * @param {integer} num | Index of slide containing image
         */
        var focusImg = function(num) {
            var winOffset;

            // Deactivate all slides
            $slides
                .css({'opacity': 0.4})
                .removeClass('focus')
                .find('.caption')
                .hide();

            // Activate selected slide
            $slides
                .eq(num)
                .css({'opacity': 1})
                .addClass('focus')
                .find('.caption')
                .show();


            if($body.width() < 580) {
                winOffset = 20;
            } else {
                winOffset = ($body.width() / 2) - 280; // Offset by the width of side menu
            }

            // Disable click and stop carousel animation on next slide
            $carousel.stop();
            $slides.off();

            //Slides to move to left or right of carousel
            var i;
            if(slideDiff > 0) {
                for(i = 0; i < slideDiff; i++) {

                    var $firstSlide = $slides.eq(i);

                    $gallery.append( $firstSlide.clone() );

                    $carousel.css({'left':  parseInt($carousel.css('left')) + $firstSlide.width()});

                    $firstSlide.remove();
                }
            } else if(slideDiff < 0) {
                for(i = 0; i > slideDiff; i--) {

                    var $lastSlide = $slides.eq( ($slides.length - 1) + i),
                        $newSlide = $lastSlide.clone();

                    $carousel.css({'left':  parseInt($carousel.css('left')) - $lastSlide.width()});
                    $lastSlide.remove();
                    $gallery.prepend( $newSlide);
                }
            }

            // Animate the carousel, reseting swiping counter and slides binding at the end
            $carousel.animate({'left': 0 - $carousel.find('.focus').position().left + winOffset}, function(){
                swipingCounter = false;
                initSlides();
            });
        };

        /**
         * Reset height and position on browser resize
         * Only Fire Resize when resize event stop triggering for 500ms
         */
        $win.on('resize', function() {
            clearTimeout(timeoutID);
            timeoutID = setTimeout(function(){
                var winOffset;

                //Resize gallery images/
                $slides.each(function(){
                    var $this = $(this),
                        $img = $this.find('img');

                    $this.css({'height': '', 'width':''});

                    if($body.width() < 580) {
                        // Mobile
                        $this.width($body.width() - 40);
                    } else {
                        // Desktop
                        $this.width($img[0].width);
                    }

                    $carousel.width( $carousel.width() + $this.width() );
                });

                // Get Gallery width again
                galleryWidth = 0;
                for(var i = 0; i < loaded; i++){
                    galleryWidth += $slides.eq(i).width();
                }
                $carousel.width( $carousel.width() + 10 );

                if($body.width() < 580) {
                    winOffset = 20;
                } else {
                    winOffset = ($body.width() - 560) / 2;
                }

                // No changing of focus
                slideDiff = 0;
                currSlide = 0;

                $carousel.animate({'left': 0 - $carousel.find('.focus').position().left + winOffset});

            }, 500);
        });

        /**
         * Touch input via Hammerjs
         */
        if(Modernizr.touch) {

            if(typeof hammertime === 'undefined') {
                hammertime = new Hammer($carousel[0]).on('swipe drag', function(e){

                    currSlide = $carousel.find('.focus').index();

                    if(!swipingCounter){
                        if(e.gesture.direction === 'left'){

                            slideDiff = 1;

                            currSlide +=1;

                            swipingCounter = true;
                            focusImg(currSlide);

                            e.gesture.preventDefault();

                        } else if(e.gesture.direction ==='right') {
                            slideDiff = -1;

                            currSlide -=1;

                            swipingCounter = true;
                            focusImg(currSlide);

                            e.gesture.preventDefault();
                        }
                    }
                });
            } else {
                hammertime.enable(true);
            }
        }

        $rightAnchor.on('click', function(e){
            if(!swipingCounter){
                currSlide = $carousel.find('.focus').index();
                slideDiff = 1;
                currSlide +=1;
                swipingCounter = true;
                focusImg(currSlide);
                e.preventDefault();
            }
        });

        $leftAnchor.on('click', function(e){
            if(!swipingCounter){
                currSlide = $carousel.find('.focus').index();
                slideDiff = -1;
                currSlide -=1;
                swipingCounter = true;
                focusImg(currSlide);
                e.preventDefault();
            }
        });
    };

    parent.carouselGallery = {
        setup: setup
    };

    return parent;

}(window.RR || {}, jQuery));


jQuery(function($){
    RR.carouselGallery.setup();
});