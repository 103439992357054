/**
 * RR - Sitemap
 */
window.RR = (function(parent, $) {
    'use strict';

    var setup = function($sitemap){

        if(!$sitemap.length) return;

        $sitemap.find('a.parent').each(function(){
            var $this = $(this),
                $ulEl = $this.next('ul'),
                $icon = $('<i class = "icon icon-plus"/>');

            $ulEl.hide();

            $this.append($icon);

            $icon.on('click', function(e) {
                if($icon.hasClass('icon-plus')) {
                    $ulEl.stop().slideDown();
                    $icon
                        .removeClass('icon-plus')
                        .addClass('icon-minus');
                } else {
                    $ulEl.stop().slideUp();
                    $icon
                        .removeClass('icon-minus')
                        .addClass('icon-plus');
                }
                e.preventDefault();
            });
        });
    };

    parent.sitemap = {
        setup: setup
    };

    return parent;

}(window.RR || {}, jQuery));