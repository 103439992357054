/**
 * RR - Side Navigation Menu
 */
window.RR = (function(parent, $) {
    'use strict';

    var $nav = $('.content-nav');

    var setup = function(){
        if(!$nav.length) return;

        var $parents = $nav.children('ul').children('li');

        // Setup inital display state
        $nav.find('li').each(function(){
            var $this = $(this);
            if(!$this.hasClass('active')) {
                $this.children('ul').hide();

                 $this
                    .find('.icon-minus')
                    .removeClass('icon-minus')
                    .addClass('icon-plus');
            }
        });

        // Find expand/collapse icon and attach behaviour to it.
        $parents.each(function(){
            var $this = $(this);

            // Bind toggle behaviour for all submenu toggle
            $this.find('.icon-minus, .icon-plus').each(function(){
                var $this = $(this),
                    $submenu = $this.closest('a').parent().children('ul, div');

                $this.on('click', function(e){

                    if($submenu.css('display') === 'block') {
                        $submenu.slideUp();
                        $this
                            .removeClass('icon-minus')
                            .addClass('icon-plus');
                    } else {
                        $submenu.slideDown();
                        $this
                            .removeClass('icon-plus')
                            .addClass('icon-minus');
                    }
                    e.preventDefault();
                });
            });
        });
    };

    parent.sideMenu = {
        setup: setup
    };

    return parent;

}(window.RR || {}, jQuery));

jQuery(function(){
    RR.sideMenu.setup();
});