'use strict';

//import node modules
import $ from 'jquery';
import 'jquery-ui';
// import 'MQ';
// import schedule from 'node-schedule';
import objectFitImages from 'object-fit-images';
import './plugins.js';
import './modules.js';
import enquire from 'enquire.js';

$(() => {

  // add custom class to sitecore form span fields with images and checkbox

  var $form = $('.sitecore-form'),
      $feedbackForm = $('.feedback-form', $form),
      $jlgForm = $('.jlgForm', $form),
      $feedbackFormDivName = $('div[class*=\'name.\']', $feedbackForm),
      $jlgFormDivName = $('div[class*=\'name.\']', $jlgForm);

    $feedbackFormDivName.find('span').has('img').has('input[type="checkbox"]').addClass('image-checkbox');

    $jlgFormDivName.find('span').has('img').has('input[type="checkbox"]').addClass('image-checkbox');

    $('a.btnrte').append('<span class="icon icon-arrow-right"></span>').addClass('btn');
    $('.title').secondLineTitleRightArrow();
    $('.list-filter-widget').filterWidget();
    $('.jump-list').jumpList();
    $('.prettify-select').prettifySelect();
    RR.pagetabs.setup($('.pagetabs'));
    RR.sitemap.setup($('.sitemap'));
    RR.exploreActivities.setup($('.explore-activities'));

    $.fn.setupAdmissionForm($('.admission'));
    $.fn.setupRegistrationForm($('.registration-form'));
    $.fn.setupSpeakerCorner($('.speakers-corner'));
    $.fn.setupEventQueue($('.event-aside-info-mobile'));
		/**
		 * Green Map
		 */
    //GreenMap.list.setup();

		/* if(typeof window.mapDirection !== "undefined") {
		GreenMap.setDirectionLink(window.mapDirection);
	}*/

		/**
		 * calling object fit images - ofi.min.js
		 */
    objectFitImages();

    /**
		 * Custom styling for search select dropdown
		 */
     (function () {
      var $select = $('select', $('.GovNav-search'));

      $select.wrap('<div class = "select"/>').before('<i class = "icon icon-arrow-dropdown"/>');
    })();

		/**
		 * Handling weather icons based on NEA API call
		 */
    (function () {
      function average(a, b) {
        var aInt = parseInt(a);
        var bInt = parseInt(b);
        return (aInt + bInt) / 2;
      }
      var e = 1;
      var n, o, r, s, a, l = "https://api.data.gov.sg/v1/environment/24-hour-weather-forecast",
        h = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        d = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        p = [],
        n = new Date;
      o = h[n.getDay()], r = n.getDate(), s = d[n.getMonth()], a = n.getFullYear();
      var f = r + " " + s + ", " + a;
      $.ajax({
        url: l,
        type: "GET",
        dataType: "json",
        headers: {
          "api-key": "781CF461BB6606ADD6253FC89A8B41F239CABC25F03650A2"
        },
        success: function (t) {
          var e, n, i = t.items[0].periods[0].regions;
          var lowTemp = t.items[0].general.temperature["low"];
          var highTemp = t.items[0].general.temperature["high"];
          r = "";
          var avgTemp = average(lowTemp, highTemp);
          $(".temp-value").text(avgTemp);
          p = t, n = p.length;
          r = i["central"];
          if (r) {
            $(".weather-state").text(r);
            var weatherText = r.toLowerCase();
            $(".nparks-weather__symbol").empty();
            switch (weatherText) {
              case "cloudy":
                $(".nparks-weather__symbol").append('<i class="icon icon-cloudy2"></i>');
                break;
              case "fair (day)":
                $(".nparks-weather__symbol").append('<i class="icon icon-sun"></i>');
                break;
              case "fair (night)":
                $(".nparks-weather__symbol").append('<i class="icon icon-moon"></i>');
                break;
              case "fair & warm":
                $(".nparks-weather__symbol").append('<i class="icon icon-sun2"></i>');
                break;
              case "light rain":
                $(".nparks-weather__symbol").append('<i class="icon icon-rainy"></i>');
                break;
              case "moderate rain":
                $(".nparks-weather__symbol").append('<i class="icon icon-rainy"></i>');
                break;
              case "partly cloudy (day)":
                $(".nparks-weather__symbol").append('<i class="icon icon-cloudy"></i>');
                break;
              case "partly cloudy (night)":
                $(".nparks-weather__symbol").append('<i class="icon icon-cloudy2"></i>');
                break;
              case "passing showers":
                $(".nparks-weather__symbol").append('<i class="icon icon-rainy"></i>');
                break;
              case "showers":
                $(".nparks-weather__symbol").append('<i class="icon icon-rainy2"></i>');
                break;
              case "slightly hazy":
                $(".nparks-weather__symbol").append('<i class="icon icon-cloudy"></i>');
                break;
              case "thundery showers":
                $(".nparks-weather__symbol").append('<i class="icon icon-rainy2"></i>');
                break;
              case "windy":
                $(".nparks-weather__symbol").append('<i class="icon icon-windy2"></i>');
                break;
              case "light showers":
                $(".nparks-weather__symbol").append('<i class="icon icon-rainy"></i>');
                break;
              default:
                $(".nparks-weather__symbol").append('<i class="icon icon-cloudy2"></i>');
            }
          }
        }
      })
    })();

    /**
		 * Handling share button
		 */

     (function () {

      let stickyWidgetShare = $('.sticky-widget__share');

      enquire.register('screen and (max-width:580px)', {

        // OPTIONAL
        // If supplied, triggered when a media query matches.
        match: function () {
          stickyWidgetShare.removeClass('allow-hover');

          stickyWidgetShare.click(function () {
            $(this).toggleClass('slideUp');
          });


          
        },

        // OPTIONAL
        // If supplied, triggered when the media query transitions
        // *from a matched state to an unmatched state*.
        unmatch: function () {
          stickyWidgetShare.addClass('allow-hover');
        },

        // OPTIONAL
        // If supplied, triggered once, when the handler is registered.
        setup: function () { },

        // OPTIONAL, defaults to false
        // If set to true, defers execution of the setup function
        // until the first time the media query is matched
        deferSetup: true,

        // OPTIONAL
        // If supplied, triggered when handler is unregistered.
        // Place cleanup code here
        destroy: function () { }

      });

      // Flex Grid Listing Mobile Dropdown
      let checkingTimeout;
      let $siteContent = $('.site-content');
      let $flexGridList = $('.flex-grid', $siteContent);
      let $checkboxDropdown = $('.checkbox-dropdown', $flexGridList);
      let $iconArrow = $('.icon-custom-arrow', $checkboxDropdown);
      let $filterOptions = $checkboxDropdown.siblings('.checkbox-wrap');
      let $checkboxItem = $('.checkbox', $filterOptions);
      let $label = $('.current', $checkboxDropdown);

      let _showOptions = (selector) => {
        let $showOpt = () => {
          let $parent = selector.parent('.form-group');
          $('.form-group').removeClass('options-shown');
          $('.form-group').find('.checkbox-wrap').slideUp('slow');
          $parent.addClass('options-shown');
          // this.optionsShown = true;
        }
        
        $.when($showOpt())
        .done(() => {
          $iconArrow.addClass('rotate');
          $filterOptions.slideDown('slow');
        })
      }
    
      let _hideOptions = (selector) => {
        let $hideOpt = () => {
          let $parent = selector.parent('.form-group');
          $parent.removeClass('options-shown');
          // this.optionsShown = false;
        }
        
        $.when($hideOpt())
        .done(() => {
          $iconArrow.removeClass('rotate');
          $filterOptions.slideUp('slow');
        })
      }

      let _updateLabel = ($cb) => {
        let selected = [];
        let noOfOptions = $checkboxItem.length;
        let checkedNo = 0;
        let allCheck = $('.checkbox.all-opt', $filterOptions).find('input');
    
        let selectedString = '';

        if($cb.val() == 'all') {

          // uncheck all checkboxes
          $checkboxItem.find('input[type="checkbox"').prop('checked', false);

          // check the all checkbox
          allCheck.prop('checked', true);

          selectedString = 'All topics';
        } else {
          allCheck.prop('checked', false);

          $checkboxItem.map((j, option) => {
            let $option = $(option);
            let $label = $('label', $option);
            let $input = $('input', $option);
      
            if ($input.is(':checked')) {
              selected.push($label.text());
              checkedNo++;
            }
          });
      
          selectedString = selected.join(', ');

          if (checkedNo === 0 || (checkedNo == (noOfOptions - 1))) {
            selectedString = 'All topics';
            $checkboxItem.find('input[type="checkbox"').prop('checked', false);
            allCheck.prop('checked', true);
          }
        }
        // this.filterControl.update(selectedString);
        
        let update = (value, array) => {
          $label.text(value);
          // this.$input.val(array);
        }
    
        update(selectedString);
      }

      $('.nice-select').niceSelect();

      if($flexGridList.length) {
        $checkboxDropdown.map((i,el) => {
          let $el = $(el);
          let $parent = $el.parent('.form-group');

          $el.on('click', e => {
            if ($parent.hasClass('options-shown')) {
              _hideOptions($el);
            } else {
              _showOptions($el);
            }
          });
        });

        $checkboxItem.map((i, ele) => {
          let $this = $(ele);
          let $label = $('label', $this);
          let $input = $('input', $this);
          
          $input.on('change', e => {
            clearTimeout(checkingTimeout);

            let $cb = $(e.currentTarget);
    
            checkingTimeout = setTimeout(() => {	
              _updateLabel($cb);
            }, 150);
          });
        });

        // All function
        // $(`input[name="allTopics"]`).map((i, ele) => {
        //   let $this = $(ele);
        //   $this.on("change", (e) => {
        //     if ($this.prop("checked") == true) {
        //       if ($this.val() === "all") {
        //         /* uncheck all other checkbox when ALL is checked, and hide all subgroups */
        //         $(`input[name!="allTopics"]`).prop("checked", false);
        //         $this.trigger('change');
        //         $this.prop("checked", true);
        //       } else {
        //         $(`input[name="allTopics"][value="all"]`).prop("checked", false);
        //       }
          
        //       // if ($(`input[name!="allTopics"]:checked`).length === $(`input.custom-checkbox`).length - 1) {
        //       //   $(`input[name!="allTopics"]`).prop("checked", false);
        //       //   (`input[name="allTopics"][value="all"]`).trigger('change');
        //       // }
        //     } else {
        //       /* when no checkbox is checked, ALL will be checked automatically */
        //       if ($(`input[name!="allTopics"]:checked`).length === 0) {
        //         $(`input[name="allTopics"][value="all"]`).prop("checked", true);
        //       }
        //     }

        //     // _updateLabel();
        //   });
        // });
        
        // Close when clicked outside
        // $(document).on('click', e => {
        //   let $eTarget = $(e.target);
        //   if (!($eTarget.hasClass('form-group') || $eTarget.parents('.form-group').length)) {
        //     _hideOptions($eTarget);
        //   }
        // });
        
      }

    }());

    /**
		 * On Media Queries
		 */
     var queries = [{
      context: 'mobile',
      match: function () {
        RR.mainMenu.mobile();
        RR.composer.mobile();
        RR.pagetabs.mobile();
        //RR.mapListing.mobile();
        RR.stickyMenu.mobile();
        RR.exploreActivities.mobile();
        RR.tabifier.setup($('.tabs'));
        // Add Jump Navigation under mobile header
        if ($('.content-nav .jump-list').length && !$('.mobile-header .jump-list').length) {
          $('.mobile-header')
            .append($('.content-nav .jump-list').clone())
            .jumpList()
            .jumpListMobileDropdown();
        }
        //related pages- remove match height
        $('.related li a').matchHeight('remove');
      },
      unmatch: function () { }
    },
    {
      context: ['desktop'],
      match: function () {
        RR.mainMenu.desktop();
        RR.pagetabs.desktop();
        RR.composer.desktop();
       // RR.mapListing.desktop();
        RR.backTop.desktop();
        RR.stickyMenu.desktop();
        RR.exploreActivities.desktop();

        RR.tabifier.setup($('.tabs'));
        //rr.siteSearch.setup();

        //related pages- match height
        $('.related li a').matchHeight();
      },
      unmatch: function () { }
    }
    ];

    MQ.init(queries);

		/**
		 * Manual execution in IE8 for desktop because media queries is not supported
		 */
    if ($('.lt-ie9').length) {
      RR.siteSearch.setup();
      RR.mainMenu.desktop();
      RR.tabifier.setup($('.tabs'));
      RR.pagetabs.desktop();
      RR.composer.desktop();
      //RR.mapListing.desktop();

      $('.related li a').matchHeight();
    }

    //JLG form
    var MAXIMUM_CHOSEN = 6;
    $('.jlgForm div[class*=\'name.\'] > .image-checkbox').click(function () {
      var $this = $(this),
        $targetChkBox = $('input[type=checkbox]', $this),
        currentClicked = $('.box-checked').length;

      if ($this.hasClass('box-checked')) {
        $targetChkBox.prop('checked', false);
        $this.removeClass('box-checked');
      } else if (currentClicked < MAXIMUM_CHOSEN) {
        $targetChkBox.prop('checked', true);
        $this.addClass('box-checked');
      }
    });

    $('.feedback-form div[class*=\'name.\'] > .image-checkbox').click(function () {
      var $this = $(this),
        $targetChkBox = $('input[type=checkbox]', $this),
        currentClicked = $('.box-checked').length;

      if ($this.hasClass('box-checked')) {
        $targetChkBox.prop('checked', false);
        $this.removeClass('box-checked');
      } else {
        $targetChkBox.prop('checked', true);
        $this.addClass('box-checked');
      }
    });

    // font resizer
    let fontRsBtn = $('.font-resizer').find('.js-resizer'),
      mainContent = $('#main');

    fontRsBtn.on('click', function (event) {
      event.preventDefault();

      mainContent.removeClass('font-normal font-big font-bigger');
      fontRsBtn.removeClass('is-active');
      $(this).addClass('is-active');

      let size = $(this).data('size'),
        sizeClass = `font-${size}`;

      mainContent.addClass(sizeClass);
    });
    $('.content-share .printer').click(function () {
      window.print();
    });
    $('.content-share .printer').keypress(function (e) {
      if (e.which == 13) { //Enter key pressed
        $(this).click();
      }
    });

    //Refresh page when tablet or mobile orientation changes
    window.onorientationchange = function () {
      window.location.reload();
    };

    //DSS guidelines

    // Listen to tab events to enable outlines
    $('body').keydown(function (e) {
      if (e.which === 9) /* tab */ {
        $(this).removeClass('no-focus-outline').addClass('has-focus-outline');
      }
    });
  
  //check social media at the footer, set display to none if there is no link
  const $siteFooterSocialMedia = $('.site-footer__social-media');

  $('a', $siteFooterSocialMedia).each(function (i, ele) {
      let socialMediaLink = $(ele).attr("href");
      if (socialMediaLink === '#' || socialMediaLink === null || socialMediaLink === '/#') {
          $(ele).css({
              display: 'none'
          })
      }
  });
});

// Passive event listeners
jQuery.event.special.touchstart = {
  setup: function( _, ns, handle ) {
      this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
  }
};
jQuery.event.special.touchmove = {
  setup: function( _, ns, handle ) {
      this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
  }
};

//$('input[type=checkbox]').click(function(){if($('input[type=checkbox]:checked').length > MAXIMUM_CHOSEN) doSomething();});