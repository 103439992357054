window.RR = (function(parent, $) {
  "use strict";

  let capacityValidationEndpoint = $(".registration-form").data("endpoint"),
    currentlyReserving = $(".registration-form").data("reserve"),
    alertMsg = $('.registration-form').data('alertMsg');

  $(".registration-form__submit-intercepter").on("click", function(e) {
    e.preventDefault();
    e.stopPropagation();

    let $this = $(this);

    //Do additional validation

    $.ajax({
      method: "GET",
      url: capacityValidationEndpoint,
      dataType: "json",
      success: function(data) {
        let vacancy =
          parseInt(data.Capacity) -
          parseInt(data.Occupied) -
          parseInt(data.CurrentlyPaying);


        if (vacancy > 0) {
          // show non-intrusive modal, letting user know places are available and they will be led to a payment page instead

          alert(alertMsg);

          // If pass then trigger this
          $this.next(".scfSubmitButton").click();
        } else {
          $this.next(".scfSubmitButton").click();
        }

      },
      error: function(error) {
        
        console.log(error);
        $this.next(".scfSubmitButton").click();

      }
    });
  });

  parent.registrationForm = {};

  return parent;
})(window.RR || {}, jQuery);
