/**
 *  RR - Carousel for homepage
 */
window.RR = (function(parent, $) {
    'use strict';

    var $carousel           = $('.content-banner .carousel'),
		$bannerCount		= $('.content-banner .carousel .banner-image').length,
        animEndEventNames   = {
                                'WebkitAnimation' : 'webkitAnimationEnd',
                                'OAnimation' : 'oAnimationEnd',
                                'msAnimation' : 'MSAnimationEnd',
                                'animation' : 'animationend'
                            },
        animEndEventName = animEndEventNames[ Modernizr.prefixed( 'animation' ) ],
        intervalID;

    /**
     * Set up homepage carousel
     */
    var setup = function(){

        if(!$carousel.length) return;

        var $banners        = $carousel.find('.gallery .banner-image'),
            $summaries      = $carousel.next().find('.banner-content'),
            $bannerNav      = $carousel.next().find('.banner-nav ul li'),
            $curr           = $banners.eq(0),
            fadeCounter     = false,
            currSlide       = 0,
            swipingCounter;

        /**
         * Lazy Load Image
         */
        var loadImg = function($this) {

            if($this.css('background-image') == 'none') {
                var img = new Image();
                img.onload = function() {
                    $this.attr('style', 'display: none; z-index:10; background-image:url('+img.src+');').fadeIn();
                };
                img.src = $this.attr('data-src');
            }
        };

        /**
         * Go to next slide
         * params { slide number }
         */
        var goToSlide = function(slideNum) {
            var $nextBanner = $banners.eq(slideNum);

            // Move active slide to top and fade in.
            loadImg($banners.eq(slideNum));

            $banners.css({'z-index': 1});
            $curr.css({'z-index': 2});

            // CSS3 End animation listener
            var listener = function(){
                fadeCounter = false;

                $nextBanner.removeClass('navInNext');
                $curr.css({'opacity' : 0}).removeClass('navOutNext');

                $curr = $nextBanner;

                $nextBanner[0].removeEventListener(animEndEventName, listener);
            };

            if(!fadeCounter) {
                fadeCounter = true;

                $bannerNav.removeClass();
                $bannerNav.eq(slideNum).addClass('active');

                if(Modernizr.cssanimations){
                    $nextBanner[0].addEventListener( animEndEventName,listener);
                    $nextBanner.css({'opacity': ''}).addClass('navInNext');

                    $curr.addClass('navOutNext');
                } else {
                    $banners.eq(slideNum).css({'z-index': 10, 'display' : 'none'}).stop().fadeIn(function() {
                            fadeCounter = false;

                            if($curr.index() !== slideNum)
                                $curr.css({'z-index': '', 'display' : 'none'});

                            $curr = $(this);
                        });
                }

                $summaries.hide();
                $summaries.eq(slideNum).show();
            }
        };

        /**
         * Go to next slide om interval
         */
        var setSlideInterval = function() {
            //Interval
            clearInterval(intervalID);
            intervalID = setInterval(function(){
                currSlide++;
                if(currSlide > $banners.length - 1) {
                    currSlide = 0;
                }
                goToSlide(currSlide);
            }, 5000);
        };

        // Set up banner navigation
        $bannerNav.each(function(){
            var $this       = $(this),
                $anchor     = $this.children('a'),
                indx        = $this.index();

            $anchor.on('click', function(e){

                if(!$this.hasClass('active')) {
                    currSlide = indx;
                    goToSlide(currSlide);
                }

                e.preventDefault();
            });
        });

		if($bannerCount > 1)
			$carousel.parent()
				.on('mouseenter', function(){
					clearInterval(intervalID);
				})
				.on('mouseleave', function(){
					setSlideInterval();
				});

        // Bootstrap Carousel
        $banners.css({'z-index': 1, 'display':'none;'});
        $summaries.eq(0).fadeIn();

        loadImg($curr);

        if($bannerCount > 1) setSlideInterval();
    };

    /**
     * Export module method
     */
    parent.carousel = {
        setup: setup
    };

    return parent;

}(window.RR || {}, jQuery));

jQuery(function($){
    RR.carousel.setup();
});
