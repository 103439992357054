/**
 *  RR - Carousel
 */
window.RR = (function(parent, $) {
    'use strict';

    var $banner  = $('.content-banner'),
        $gallery = $banner.children('.pano-gallery'),
        hammertime;

    var setup = function(){

        if(!$gallery.length) return;

        var $dragHelper  = $gallery.find('.drag-indicator'),
            $rightHelper = $gallery.find('.right-pointer'),
            $leftHelper  = $gallery.find('.left-pointer');

        var $banner =  $gallery.find('.gallery .banner-image'),
            $galleryNav = $gallery.find('.slide-gallery li');

        var hideHelper = function(){
            $dragHelper.hide();
            $rightHelper.hide();
            $leftHelper.hide();
        };

        var loadImg = function($this) {
            if($this.css('background-image') == 'none') {
                var img = new Image();

                $banner.css({'left': ''});

                img.onload = function() {

                    if(img.width > $gallery.width()) {
                        $this.attr('style', 'z-index:10; background-image:url('+img.src+'); width:' + img.width +'px; left:' + -(img.width - $gallery.width())/2 + 'px;').fadeIn(500);
                        $dragHelper.fadeIn();
                        $rightHelper.fadeIn();
                        $leftHelper.fadeIn();
                    } else {
                        $this.attr('style', 'z-index:10; background-image:url('+img.src+'); width:' + img.width +'px; left: 50%; margin-left:' + -img.width/2 +'px;').fadeIn(500);
                        hideHelper();
                    }

                    $gallery.animate({'height': img.height + 'px'});
                };

                img.src = $this.attr('data-src');
            }
        };

        // Use hammerjs for ie 9 and above
        if(!$('.lt-ie9').length){
            hammertime = new Hammer($('.content-banner')[0]).on('swipe drag', function(e){

                if(e.gesture.direction === "left" || e.gesture.direction == "right") {
                    if($banner.width() > $gallery.width()) {
                        swipeImage(e.gesture.deltaX, $banner);
                    }

                    e.gesture.preventDefault();
                }
            });
        }



        // Fallback for IE 8 and below
        (function(){
            if(!$('.lt-ie9').length) return;
            var xpos            = 0,
                drag            = false,
                $contentBanner  = $('.content-banner')[0];

            $contentBanner.attachEvent('onmousedown', function(e){
                xpos = e.offsetX;
                drag = true;
            });

            document.attachEvent('onmousemove', mousemove);

            document.attachEvent('onmouseup', function(e){
                drag = false;
            });

            function mousemove(e) {
                if(drag === true) {
                   var offset = xpos - e.offsetX;
                   swipeImage((offset/4)*-1, $banner);
                }
            }
        }());


        var swipeImage = function(deltaX, $obj){
            var currLeft    = Math.ceil($obj.position().left),
                rightLimit  = -$obj.width() + $gallery.width(),
                leftLimit   = 0,
                displace    = Math.ceil(currLeft + (deltaX * 0.2));

            $dragHelper.fadeOut();

            if(displace > leftLimit) {
                $obj.css({'left': leftLimit });
                $leftHelper.fadeOut();
            } else if(displace < rightLimit) {
                $obj.css({'left': rightLimit });
                $rightHelper.fadeOut();
            } else {
                $obj.css({'left': displace });
                $leftHelper.fadeIn();
                $rightHelper.fadeIn();
            }
        };


        if($galleryNav.length) {
            $galleryNav.children('a').on('click', function(e){
                var $this = $(this);

                $banner.css({'background-image' : 'none'});
                $banner.attr('data-src', $this.attr('href'));
                $banner.css({'left':''});

                $galleryNav.removeClass();

                $this.parent().addClass('active');

                loadImg($banner);
                hideHelper();

                e.preventDefault();
            });
        }

        loadImg($banner);
        hideHelper();
        $galleryNav.eq(0).addClass('active');
    };

    parent.panoGallery = {
        setup: setup
    };

    return parent;

}(window.RR || {}, jQuery));

jQuery(function($){
    RR.panoGallery.setup();
});