/**
 * RR - Dom Composer(Helper module)
 */
window.RR = (function (parent, $) {
    'use strict';

    var $objs = $('*[data-composer]');

    var desktop = function(){
        $objs.each(function(){
            var $this = $(this),
                param = $this.data('composer-params-desktop').split(","),
                $parent = $(param[0]),
                method = $.trim(param[1]);

            compose($parent, $this, method);
        });
    };

    var mobile = function(){
        $objs.each(function(){
            var $this = $(this),
                param = $this.data('composer-params-mobile').split(","),
                $parent = $(param[0]),
                method = $.trim(param[1]);

            compose($parent, $this, method);
        });
    };

    var compose = function($parent, $obj, method) {
        switch (method) {
            case 'append':
                $parent.append($obj);
                break;
            case 'prepend':
                $parent.prepend($obj);
                break;
            case 'after':
                $parent.after($obj);
                break;
            case 'insertAfter':
                $obj.insertAfter($parent);
                break;
            case 'before':
                $parent.before($obj);
                break;
        }
    };

    /**
     * Expose module methods
     */
    parent.composer = {
        desktop: desktop,
        mobile: mobile
    };

    return parent;


}(window.RR || {}, jQuery));