'use strict';

import 'hammerjs';
import 'humanize-duration';
import './plugins/bootstrap';
import './plugins/consoleError';
import './plugins/jquery.ba-dotimeout';
import './plugins/jquery.custom';
import './plugins/jquery.matchHeight-min';
import './plugins/zebra_datepicker';
import './plugins/find.polyfill';
import './plugins/object-assign-polyfill';
import './plugins/nice-select';
import './plugins/nice-select-with-search-multiple';