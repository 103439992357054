/**
 *  RR - Main Navigation Menu
 */
window.RR = (function(parent, $) {
    'use strict';

    var $win        = $(window),
        $nav        = $('.site-nav'),
        $menuList   = $nav.find('.nav-list'),
        $parents    = $menuList.children('li'),
        $sideNav    = $('.content-nav'),
        $menuToggle = $('<div class="menu-toggle icon icon-menu"></div>');

    var setup = function() {
        var $globalNotices = $('.global-notices'),
            $alertBtn = $('.site-alerts a');

        /**
         * Set Global Notifications
         */
        (function(){
            // Exit if there's no notices
            if(!$globalNotices.length) return;

            var counter = $alertBtn.parent().data('counter'),
                $number = $alertBtn.find('.number');

            $number.text(counter);

            $alertBtn.on('click', function(e){
                if($globalNotices.css('display') == 'none'){
                    $globalNotices.slideDown();
                    $alertBtn.addClass('on');
                    $('html, body').stop().animate({'scrollTop': 0});
                } else {
                    $globalNotices.slideUp();
                    $alertBtn.removeClass();
                }
                e.preventDefault();
            });

            if(counter > 0) {
                $number.removeClass('zero');
            }

        }());
    };


    var desktop = function() {
        /**
         * Reset Mobile
         */
        $nav.find('.icon-plus, .icon-minus').remove();
        $menuList.css('display', '');

        $parents.each(function(){
            var $this    = $(this),
                $link    = $this.children('a'),
                $subLinks = $link.next('ul').find('li');

            $link.on('mouseenter touchend focus', function(e){
                $.doTimeout('hover', 100, function(){

                    $parents.children('ul').stop().hide();
                    $parents.children('a').removeClass('hover');

                    $link
                        .addClass('hover')
                        .next('ul')
                        .fadeIn();
                });
            });

            $this.on('mouseleave', function(){
                $.doTimeout('hover', 0, function(){
                    $link.removeClass('hover');

                    $link.next('ul').hide();
                });
            });

            /**
             * Level 2 Menu
             */
            $subLinks.each(function(){
                var $this    = $(this),
                    $submenu = $this.children('ul');

                if($submenu.length) {
                    $this.on('mouseenter', function(){
                        $submenu.show();
                        $this.addClass('active');
                    });

                    $this.on('mouseleave', function(){
                        $submenu.hide();
                        $this.removeClass('active');
                    });
                }
            });
        });

        /**
         * Hide menu on click outside
         */
        var hideMenu = function(e) {
            var $target = $(e.target);

            if(!$target.closest('.site-nav').length) {
                $parents.children('a').removeClass('hover');
                $parents.children('ul').hide();
            }
        };

        $('body').on('click touchend', hideMenu);
    };


    var mobile = function() {
        /**
         * Reset Desktop
         */
        $menuList.find('li, a').off();

        if(!$('.menu-toggle').length) {
            $nav.find('.container').append($menuToggle);

            $menuToggle.click(function(e){
                $menuList.slideToggle(function(){
                    // Make sure sticky menu still work
                    if($menuList.css('display') === 'block'){
                        if($nav.hasClass('sticky')) {
                            $nav.css({
                                'position' : 'absolute',
                                'top'      : $win.scrollTop()
                            });

                            $win.on( 'scroll',toggleMenuSnap );
                        }

                    } else {
                        $nav.css({
                            'position' : '',
                            'top'      : ''
                        });

                         $win.off('scroll',toggleMenuSnap );
                    }
                });
                e.preventDefault();
            });
        }

        //Reset if scroll up
        var toggleMenuSnap = function(){
            var currScroll = $win.scrollTop();

            if(currScroll < $nav.position().top){
                $nav.css({
                    'position' : 'absolute',
                    'top'      : currScroll
                });
            }

            if(currScroll > $nav.position().top + $win.height()/2){
                $nav.css({
                    'position' : '',
                    'top'      : ''
                });
                $menuList.hide();
            }

            // Remove binding if 'sticky' is removed
            if(!$nav.hasClass('sticky')) {
                 $nav.css({
                    'position' : '',
                    'top'      : ''
                });
                $win.off('scroll',toggleMenuSnap );
            }
        };

        $parents.each(function(){
            if ($(this).find('ul').length) {
                $(this).append('<span class="icon icon-plus"></span>');
            }
        });

        $menuList.find('li.active')
            .children('.icon')
            .toggleClass('icon-plus')
            .toggleClass('icon-minus')
            .children('ul').show();

        $menuList.find('li > .icon').click(function(){
            $(this)
                .toggleClass('icon-plus')
                .toggleClass('icon-minus')
                .siblings('ul')
                .slideToggle();
        });

        /**
         * Modify Main Nav Menu
         */
        (function(){
            if(!$sideNav.length) return;

            var $currActive = $sideNav.find(' > ul > .active > ul').clone(), // target in side navigation menu
                $target = $menuList.find('> .active > ul > .active'); // target in main navigation menu

            if(!$target.find('.sub-nav').length) {
                // Add 'sub-nav' class
                $currActive.addClass('sub-nav');

                // Find jump to section list and apply jumpList plugin
                $currActive.find('.jump-list').jumpList();

                // Remove pointer icon
                $currActive.find('.icon').remove();

                // Append cloned side navigation menu target to main navigation menu
                $target.append($currActive);
            }
        }());
    };

    parent.mainMenu = {
        setup: setup,
        desktop: desktop,
        mobile: mobile
    };

    return parent;

}(window.RR || {}, jQuery));

jQuery(function($){
    RR.mainMenu.setup();
});
