/**
 *  RR - Sticky Menu
 */
window.RR = (function(parent, $){
    'use strict';

    var $mainNav    = $('.site-nav'),
        $mainMenu   = $mainNav.find('.nav-list'),
        $backToTop  = $('<li class="back-top"></li>'),
        $backLink   = $('<a href="#"><i class="icon icon-arrow-up"></i>Back to top</a>'),
        $siteHeader = $('.site-header');

    /**
     * Scroll Event
     */
    var scrollFunc = function(){
        var currScroll = $(window).scrollTop();

        if(currScroll > $mainNav.scrollTop() + 100) {
            // Make sure menu is closed before making it sticky
            if($mainMenu.css('display') !== 'block') {
                $mainNav.addClass('sticky');
                $siteHeader.css({'padding-bottom' : '60px' });
            }
        } else {
            $mainNav.removeClass('sticky');
            $siteHeader.css({'padding-bottom' : '' });
        }
    };

    var desktop = function(){
         $(window).off('scroll', scrollFunc);
    };

    var mobile = function() {
        if(!$mainNav.length) return;

        $backLink.on('click', function(e){
            $('html, body').stop().animate({'scrollTop': 0});
            $mainMenu.css({'display': 'none'});
        });

        $backToTop.append($backLink);
        $mainMenu.prepend($backToTop);

        $(window)
            .on('scroll', scrollFunc)
            .trigger('scroll');
    };

    /**
     * Export module method
     */
    parent.stickyMenu = {
        desktop: desktop,
        mobile: mobile
    };

    return parent;

}(window.RR || {}, jQuery));