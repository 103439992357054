/**
 * RR - Homepage - Explore Activities
 */
window.RR = (function(parent, $) {
    'use strict';

    var $tabs, $contents, $section;

    /**
     * Bootstrap Explore Activities section
     * params { 'Explore Activities DOM Object' }
     */
    var setup = function($obj){
        if(!$obj.length) return;

        $section = $obj;

        $tabs = $obj.find('.explore-activities-tabs li');
        $contents = $obj.find('.explore-activities-content .tab-content');
    };


    /**
     * Desktop breakpoint functionalities
     */
    var desktop = function() {
        // Return if 'Explore Activities' DOM Object is not defined'
        if(typeof $section === "undefined") return;

        var $anchors = $tabs.find('a'),
            curr;

        $contents.find('.columns').css({'display':''});
        $anchors.removeClass('active');

        $anchors.each(function(){
            var $this = $(this);

            $this.on('click', function(e){
                $anchors.removeClass();
                $this.addClass('active');
                showContent($this.parent().index());

                e.preventDefault();
            });
        });


        /**
         * Display content of selected activity
         */
        var showContent = function(i) {
            var $wrapper =  $section.find('.explore-activities-content');

            if($wrapper.css('display') === 'block') { // If wrapper is collapsed

                $contents.eq(curr).animate({'left':'-1000px', 'opacity': 0 });
                $contents.eq(i)
                    .css({'left':'1000px', 'opacity': 0, 'display': 'block'})
                    .animate({'left': 0, 'opacity': 1});

            } else {
                $contents.hide();
                $contents.eq(i).show();
                $wrapper.slideDown(function(){
                    $wrapper.css({'height': $contents.eq(i).height(), 'overflow':'hidden'});
                    $contents.css({'position': 'absolute'});
                });

                //Auto scroll section to top
                $('html, body').stop().animate({'scrollTop': $section.position().top});
            }

           curr = i; // Update curr to current item index
        };
    };


    /**
     * Mobile breakpoint functionalities
     */
    var mobile = function() {
        // Return if 'Explore Activities' DOM Object is not defined'
        if(typeof $section === "undefined") return;

        $contents.each(function(){
            var $this = $(this);

            $this.css({'position':'', 'opacity':'', 'display':''});
            $section.find('.explore-activities-content').css({'height':'', 'display': ''});

            if($this.children('.tab').length) {
                $this.find('.active').removeClass('active');
                return;
            }
            var $tab = $tabs.eq($this.index()).children('a').clone();

            $tab.on('click', function(e){
                var $content = $this.children('.columns');

                if($content.css('display') === 'block') {
                    $this.children('.columns').slideUp();
                    $tab.removeClass('active');
                } else {
                    $this.children('.columns').slideDown();
                    $tab.addClass('active');
                }

                e.preventDefault();
            });

            //Prepend accordion button to each item
            $this.prepend($tab.addClass('tab'));
        });
    };

    /**
     * Expose module methods
     */
    parent.exploreActivities = {
        setup: setup,
        desktop: desktop,
        mobile: mobile
    };

    return parent;

}(window.RR || {}, jQuery));