import flatpickr from 'flatpickr';

window.RR = (function (parent, $) {
  'use strict';


  /** DEFINE CONTAINERS **/
  let $formIntro = $('.form-intro'),
    $individualForm = $('#individualDetail'),
    $entityForm = $('#entityDetail'),
    $conditionsForm = $('#conditions'),
    $conditionsWrap = $('.conditions-wrap'),
    $entireForm = $('.scfForm'),
    $speakersCornerNotice = $('.speakers-corner__notice');

  /** DEFINE MISC VARIABLES **/
  let formType = "",
    eventCode = "",
    isNew = true,
    isIndividualForm = true,
    $startForm = $('.nextBtn', $speakersCornerNotice),
    $individualNextBtn = $individualForm.find('.scfSubmitButton'),
    $entityNextBtn = $entityForm.find('.scfSubmitButton'),
    backBtn = $('#backBtn', $entireForm),
    submitWrap = $('.submit', $conditionsWrap),
    submitConditions = $('.btn', submitWrap),
    timeValidator = $('.timeValidator');

  /** DEFINE FIELDS **/
  let expectedSizeInput = $('#contentplaceholder_0_entityEventDetail_estimatedSizeTextBox'),
    expectedSizeNotice = $('#contentplaceholder_0_entityEventDetail_regexEstimatedSizeValidator'),
    startTime = $('.scf-event-starttime'),
    endTime = $('.scf-event-endtime'),
    $singleLineTextBox = $('.scfSingleLineTextBox'),
    multipleLineTextBox = $('.scfMultipleLineTextBox'),
    $activitiesCheckbox = $('.concheck').find('input'),
    $speakerDatepicker = $('.speaker-datepicker'),
    $formConsentCheckBox = $('#contentplaceholder_0_formConsentCheckBox');

  /** DEFINE BUTTONS **/
  let $entitySubmitBtn = $('#contentplaceholder_0_entitySubmitButton'),
    $individualSubmitBtn = $('#contentplaceholder_0_submitButton'),
    $formSubmitButtons = $('#contentplaceholder_0_entitySubmitButton, #contentplaceholder_0_submitButton'),
    $individualBackBtn = $individualForm.find('.back'),
    $entityBackBtn = $entityForm.find('.back'),
    $conditionsBackBtn = $conditionsForm.find('.back');



  $formSubmitButtons.click(function (e) {


    // if $formConsentCheckBox not checked, prevent submit
    if (!$formConsentCheckBox.is(':checked')) {

      e.preventDefault();

    }


  });



  // if eventid is provided as paramater, go to screen 2 immediately
  if (findUrlParam('eventid')) {
    showForm($entireForm);
    hideForm($speakersCornerNotice);
  }


  $conditionsBackBtn.click(function () {

    hideForm($conditionsForm);

    if (isIndividualForm) {
      showForm($individualForm);
    } else {
      showForm($entityForm);
    }

  });

  $individualBackBtn.click(function () {
    // hide individual form
    // show page 2
    hideForm($individualForm);
    showForm($formIntro);

  });

  $entityBackBtn.click(function () {
    // hide entity form
    hideForm($entityForm);
    showForm($formIntro);

  })

  submitConditions.on('click', function () {
    if (expectedSizeInput.val().length == 0) {
      expectedSizeNotice.show();
    } else {
      expectedSizeNotice.hide();
    }
  });


  startTime.on('change', function () {
    $('#contentplaceholder_0_entityEventDetail_endTimeDropDown_Validation').hide();
    $('#contentplaceholder_0_individualEventDetail_endTimeDropDown_Validation').hide();
    let $this = $(this),
      parentDropListBorder = $this.closest('.scfDropListBorder'),
      siblingDropListBorder = parentDropListBorder.siblings('.scfDropListBorder'),
      $endTime = siblingDropListBorder.find('.scf-event-endtime'),
      $validationMessage = $this.siblings('.scfValidator').not('.timeValidator');

    // hide error message
    $validationMessage.hide();

    validateTime($this.val(), $endTime.val(),'');

  });


  endTime.on('change', function () {
    $('#contentplaceholder_0_entityEventDetail_endTimeDropDown_Validation').hide();
    $('#contentplaceholder_0_individualEventDetail_endTimeDropDown_Validation').hide();
    let $this = $(this),
      parentDropListBorder = $this.closest('.scfDropListBorder'),
      siblingDropListBorder = parentDropListBorder.siblings('.scfDropListBorder'),
      $startTime = siblingDropListBorder.find('.scf-event-starttime'),
      $validationMessage = $this.siblings('.scfValidator').not('.timeValidator');

    $validationMessage.hide();

    validateTime($startTime.val(), $this.val(),'');
  });

  $activitiesCheckbox.on('change', function () {
    let value = $(this).val();

    console.log(value);

    if ($(this).is(':checked')) {
        $(`div[data-show="${value}"]`).removeClass('hide');
      $(`div[data-show="${value}"]`).addClass('show');
    } else {
        $(`div[data-show="${value}"]`).removeClass('show');
        $(`div[data-show="${value}"]`).addClass('hide');
    }

    if ($('.concheck input:checked').length > 0) {
      $conditionsWrap.removeClass('hide');
      $conditionsWrap.addClass('show');
    } else {
        $conditionsWrap.removeClass('show');
        $conditionsWrap.addClass('hide');
    }

  })

  $startForm.on('click', function () {
    showForm($entireForm);
    hideForm($speakersCornerNotice);
  });

  backBtn.on('click', function () {
    hideForm($entireForm);
    showForm($speakersCornerNotice);
    hideForm($conditionsForm);
  });


  // allow numerical input only
  forceNumericOnly($('.numerical'));

  // removing errors on typing
  $singleLineTextBox.on('keydown', function () {
    showError($(this), false);
  });

  multipleLineTextBox.on('keydown', function () {
    showError($(this), false);
  });

  // removing errors on file upload
  $('input[type="file"]').on('change', function () {
    let errorholder = $(this).siblings('.scfValidator');
    errorholder.hide();
  });



  // do submission part for entity form portion
  $entityNextBtn.on('click', function () {

    let name = $('#contentplaceholder_0_entityNameTextBox'),
      uen = $('#contentplaceholder_0_entityUENTextBox'),
      contactee = $('#contentplaceholder_0_entityContactPersonTextBox'),
      contact = $('#contentplaceholder_0_entityContactNumberTextBox'),
      email = $('#contentplaceholder_0_entityContactEmailTextBox'),
      streetName = $('#contentplaceholder_0_entityStreetNameTextBox'),
      houseNo = $('#contentplaceholder_0_entityUnitNumberTextBox'),
      postalCode = $('#contentplaceholder_0_entityPostalCodeTextBox'),
      date = $('#contentplaceholder_0_entityEventDetail_txtDate'),
      startTime = $('#contentplaceholder_0_entityEventDetail_startTimeDropDown'),
      endTime = $('#contentplaceholder_0_entityEventDetail_endTimeDropDown'),
      title = $('#contentplaceholder_0_entityEventDetail_topicTextBox'),
      structureRadio = $('input[name="contentplaceholder_0$entityEventDetail$hasLayoutStructureRadio"]'),
      tnc = $('#contentplaceholder_0_entityEventDetail_formConsentCheckBox'),
      fileupload = $('#contentplaceholder_0_entityEventDetail_layoutStructureFileUpload'),
      expectedCrowdSize = $('#contentplaceholder_0_entityEventDetail_estimatedSizeTextBox');

    let textInputs = [name, uen, contactee, contact, streetName, houseNo, postalCode, date, startTime, endTime, title, expectedCrowdSize],
      valid = true;

    textInputs.forEach(function (input, idx) {

      if (!validateInput(input)) {

        showError(input, true);
        valid = false;

      } else {

        showError(input, false);

      }
    })

    if (!validateEmail(email.val())) {
      showError(email, true);
      valid = false;
    } else {
      showError(email, false);
    }

    if (!structureRadio.is(':checked')) {
      valid = false;
      $('#contentplaceholder_0_entityEventDetail_RequiredHasLayoutStructureValidator').show();
    } else {
      $('#contentplaceholder_0_entityEventDetail_RequiredHasLayoutStructureValidator').hide();

      if ($('#contentplaceholder_0_entityEventDetail_hasLayoutStructureRadio_0').is(':checked')) {
        if (!validateInput(fileupload)) {
          showError(fileupload, true);
          valid = false;
        } else {
          showError(fileupload, false);
        }
      }

    }
   
    let startTimeVal = startTime.val();
    let endTimeVal = endTime.val();
    let dateVal=date.val();
    let currentDate = new Date(); 
    let formattedCurrentTime = (currentDate.getHours() < 10 ? '0' : '') + currentDate.getHours() + '' + (currentDate.getMinutes() < 10 ? '0' : '') + currentDate.getMinutes();
    let isSameDate=false;

    var formattedInputDate = new Date(dateVal);
    var formattedInputNewDate = formattedInputDate.getDate() + " " + (formattedInputDate.getMonth() + 1) + " " + formattedInputDate.getFullYear();
    var formattedCurrentDate = currentDate.getDate() + " " + (currentDate.getMonth() + 1) + " " + currentDate.getFullYear();
    if (!findUrlParam('eventid')) {
        if (formattedInputNewDate == formattedCurrentDate) {
            if (parseInt(formattedCurrentTime) > parseInt(startTimeVal) || parseInt(formattedCurrentTime) > parseInt(endTimeVal)) {
                isSameDate = true;
                $('#contentplaceholder_0_entityEventDetail_endTimeDropDown_Validation').show();
            }
        }
    }

    let timeCheck = validateTime(startTimeVal, endTimeVal,isSameDate);

    if (!timeCheck) {
      valid = false;
    }

    if (valid) {
      hideForm($individualForm);
      hideForm($entityForm);
      showForm($conditionsForm);
    }

  })

  $individualNextBtn.on('click', function () {

    let name = $('#contentplaceholder_0_nameTextBox'),
      nric = $('#contentplaceholder_0_idTextBox'),
      contact = $('#contentplaceholder_0_mobileTextBox'),
      email = $('#contentplaceholder_0_emailTextBox'),
      streetName = $('#contentplaceholder_0_streetNameTextBox'),
      houseNo = $('#contentplaceholder_0_unitNumberTextBox'),
      postalCode = $('#contentplaceholder_0_postCodeTextBox'),
      date = $('#contentplaceholder_0_individualEventDetail_txtDate'),
      startTime = $('#contentplaceholder_0_individualEventDetail_startTimeDropDown'),
      endTime = $('#contentplaceholder_0_individualEventDetail_endTimeDropDown'),
      title = $('#contentplaceholder_0_individualEventDetail_topicTextBox'),
      structureRadio = $('input[name="contentplaceholder_0$individualEventDetail$hasLayoutStructureRadio"]'),
      tnc = $('#contentplaceholder_0_individualEventDetail_formConsentCheckBox'),
      $singleLineTextBox = $('.scfSingleLineTextBox'),
      multipleLineTextBox = $('.scfMultipleLineTextBox'),
      fileupload = $('#contentplaceholder_0_individualEventDetail_layoutStructureFileUpload'),
      expectedCrowdSize = $('#contentplaceholder_0_individualEventDetail_estimatedSizeTextBox');


    let textInputs = [name, contact, streetName, houseNo, postalCode, date, startTime, endTime, title, expectedCrowdSize],
      valid = true;

    textInputs.forEach(function (input, idx) {
      if (!validateInput(input)) {
        showError(input, true);
        valid = false;
        timeValidator.hide();
      } else {
        showError(input, false);
      }
    })

    // validate nric
    if (!validateNRIC(nric.val())) {
      showError(nric, true);
      valid = false;
    } else {
      showError(nric, false);
    }

    if (!validateEmail(email.val())) {
      showError(email, true);
      valid = false;
    } else {
      showError(email, false);
    }


    if (!structureRadio.is(':checked')) {
      valid = false;

      $('#contentplaceholder_0_individualEventDetail_RequiredHasLayoutStructureValidator').show();

    } else {

      $('#contentplaceholder_0_individualEventDetail_RequiredHasLayoutStructureValidator').hide();

      if ($('#contentplaceholder_0_individualEventDetail_hasLayoutStructureRadio_0').is(':checked')) {
        if (!validateInput(fileupload)) {
          showError(fileupload, true);
          valid = false;
        } else {
          showError(fileupload, false);
        }
      }

    }
    
    let startTimeVal = startTime.val();
    let endTimeVal = endTime.val();
    let dateVal=date.val();
    let currentDate = new Date(); 
    let formattedCurrentTime = (currentDate.getHours() < 10 ? '0' : '') + currentDate.getHours() + '' + (currentDate.getMinutes() < 10 ? '0' : '') + currentDate.getMinutes()
    let isSameDate=false;

    var formattedInputDate = new Date(dateVal);
    var formattedInputNewDate = formattedInputDate.getDate() + " " + (formattedInputDate.getMonth() + 1) + " " + formattedInputDate.getFullYear();
    var formattedCurrentDate = currentDate.getDate() + " " + (currentDate.getMonth() + 1) + " " + currentDate.getFullYear();

    if (!findUrlParam('eventid')) {
        if (formattedInputNewDate == formattedCurrentDate) {
            if (parseInt(formattedCurrentTime) > parseInt(startTimeVal) || parseInt(formattedCurrentTime) > parseInt(endTimeVal)) {
                isSameDate = true;
                $('#contentplaceholder_0_individualEventDetail_endTimeDropDown_Validation').show();
            }
        }
    }

    let timeCheck = validateTime(startTimeVal, endTimeVal, isSameDate);

    if (!timeCheck) {
      valid = false;
    }

    if (valid) {
      hideForm($individualForm);
      hideForm($entityForm);
      showForm($conditionsForm);
    }
  })


  // helper functions
  /** CHECK IF URL PARAM IS PRESENT **/
  function findUrlParam(name) {

    let url = window.location.href;

    if (url.indexOf('?' + name + '=') != -1) {

      return true;

    } else {

      return false;

    }

  }

  function validateTime(startTime, endTime,IsSameDateMatch) {      
      var start = startTime;
      var end = endTime;

    if (start.length && end.length) {
      if (start >= end) {
        timeValidator.show();
        return false;
      }
      else if (IsSameDateMatch == true) {
          timeValidator.show();          
          return false;
      }    
      else {
        timeValidator.hide();
        return true;
      }
    }
  }

  function forceNumericOnly($ele) {
    $ele.keydown(function (e) {
      var key = e.charCode || e.keyCode || 0;
      // allow backspace, tab, delete, enter, arrows, numbers and keypad numbers ONLY
      // home, end, period, and numpad decimal
      return (
        key == 8 ||
        key == 9 ||
        key == 13 ||
        key == 46 ||
        key == 110 ||
        key == 190 ||
        (key >= 35 && key <= 40) ||
        (key >= 48 && key <= 57) ||
        (key >= 96 && key <= 105));
    });
  }

  function validateEmail(email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  }

  function validateInput(input) {
    if (input.val().length < 1) {
      return false;
    } else {
      return true;
    }
  }

  function showError(input, boolean, txt) {

    if (input.hasClass('speaker-datepicker')) {

      let validator = input.closest('.scfDatePickerTextPanel').siblings('.scfValidator');

      if (boolean) {
        if (txt) {
          validator.text(txt);
        }
        validator.show();
      } else {
        validator.hide();
      }
    } else {

      let validator = input.siblings('.scfValidator');

      if (boolean) {
        validator.show();
      } else {
        validator.hide();
      }
    }
  }

  function hideForm(form) {
    form.removeClass('visible').addClass('hide');
  }

  function showForm(form) {
    form.removeClass('hide').addClass('visible');
  }

  var setFormType = function (value) {
    if (isValidFormType(value)) {
      formType = value;
    } else {
      formType = '';
    }
  }

  var isValidFormType = function (value) {
    return (value === 'individual') || (value === 'entity');
  }

  var setIsNew = function (value) {
    isNew = value;
    if (isNew) {
      $("#section-event-code").fadeOut();
    } else {
      $("#section-event-code").fadeIn();
    }
  }

  var isEmpty = function (str) {
    return (!str || 0 === str.length);
  }

  var render = function (step) {

    if (step === 1) {

      $entitySubmitBtn.hide();
      $individualSubmitBtn.hide();

      hideForm($formIntro);

      if (formType === 'individual') {

        isIndividualForm = true;

        showForm($individualForm);
        hideForm($entityForm);
        $individualSubmitBtn.show();

      } else {

        isIndividualForm = false;

        hideForm($individualForm);
        showForm($entityForm);
        $entitySubmitBtn.show();

      }

    }

  }

  var validateStartDate = function (sender) {
    var $sender = $(sender),
      $container = $sender.closest('.scf-event-container'),
      requiredValidator = window[$container.data('startdate-requiredvalidator')],
      validator = window[$container.data('starttime-validator')];
    if (requiredValidator != null) {
      // ValidatorValidate(requiredValidator, requiredValidator.validationGroup, null);
      if (requiredValidator.isvalid && validator != null) {
        // ValidatorValidate(validator, validator.validationGroup, null);
      }
    }
  }

  var validateEndTime = function (sender) {
    var $sender = $(sender),
      $container = $sender.closest('.scf-event-container'),
      validator = window[$container.data('starttime-customvalidator')];
    if (validator != null) {
      // ValidatorValidate(validator, validator.validationGroup, null);
    }
  }

  var duration_ClientValidate = function (sender, args) {
    args.IsValid = false;

    var $ddlEndTime = $(sender).parents(".scfDropListBorder").next().find(".scfDropList");
    if ($ddlEndTime.length) {
      if ((parseInt(args.Value) || 0) <= (parseInt($ddlEndTime.val()) || 0)) {
        args.IsValid = true;
      }
    }
  }

  var startTimeValidator_ClientValidate = function (sender, args) {
    args.IsValid = false;

    var $ddlStartTime = $(sender).parents(".scfDateSelectorBorder").next().find(".scfDropList");
    if ($ddlStartTime.length) {
      var selectedDate = parseDate(args.Value);
      if (selectedDate != null) {
        selectedDate.setMinutes(getTotalMinutes($ddlStartTime.val()));
        args.IsValid = selectedDate > (new Date());
      }
    }

    if (!args.IsValid) {
      sender.innerHTML = "<span>Starting Time must be later than current time</span>";
      sender.errormessage = "Starting Time must be later than current time";
    }
  }

  var getTotalMinutes = function (value) {
    var result = 0, strlen = 0;
    if (value) {
      strlen = value.length;
      if (strlen == 4) {
        result = (parseInt(value.substr(0, 2)) || 0) * 60;
        result += parseInt(value.substr(strlen - 2, 2)) || 0;
      }
    }
    return result;
  }

  var parseDate = function (value) {
    var result = null, parts = [];
    if (value !== "") {
      try {
        parts = value.split(" ");
        result = new Date((parts[2] * 1), ($.inArray(parts[1].toUpperCase(),
          ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]) * 1), parts[0] * 1);
      }
      catch (err) { }
    }
    return result;
  }

  var reveal = function (sender, id) {
    var $sender = $(sender);
    switch (id) {
      case 0:
        $('#prSpan').hide();
        $('#otherSpan').hide();
        $sender.closest("div.scfRadioButtonListBorder").nextAll().fadeIn();
        $sender.closest("fieldset").siblings().fadeIn();
        break;
      case 1:
        $('#prSpan').show();
        $('#otherSpan').hide();
        $sender.closest("div.scfRadioButtonListBorder").nextAll().fadeOut();
        $sender.closest("fieldset").siblings().fadeOut();
        break;
      case 2:
        $('#prSpan').hide();
        $('#otherSpan').show();
        $sender.closest("div.scfRadioButtonListBorder").nextAll().fadeOut();
        $sender.closest("fieldset").siblings().fadeOut();
    }
  }

  var revealForeignEntity = function (sender, isSelected) {
    var $sender = $(sender);
    if (isSelected) {
      $sender.closest(".scfRadioButtonList").siblings(".foreign").fadeIn();
      $sender.closest("div.scfRadioButtonListBorder").nextAll().fadeOut();
      $sender.closest("fieldset").siblings().fadeOut();
    } else {
      $sender.closest(".scfRadioButtonList").siblings(".foreign").fadeOut();
      $sender.closest("div.scfRadioButtonListBorder").nextAll().fadeIn();
      $sender.closest("fieldset").siblings().fadeIn();
    }
  }

  var isPerformanceExhibitionCheck = function () {
    $(".is-performance-type").each(function () {
      $(this).toggle();
    });
  }

  var showStructureFileUpload = function (val) {
    $(".has-layout").each(function () {
      $(this).toggle(val);
    });
  }

  var validateFIN = function (str) {
    if (str.length != 9)
      return false;

    str = str.toUpperCase();

    var i,
      icArray = [];
    for (i = 0; i < 9; i++) {
      icArray[i] = str.charAt(i);
    }

    icArray[1] = parseInt(icArray[1], 10) * 2;
    icArray[2] = parseInt(icArray[2], 10) * 7;
    icArray[3] = parseInt(icArray[3], 10) * 6;
    icArray[4] = parseInt(icArray[4], 10) * 5;
    icArray[5] = parseInt(icArray[5], 10) * 4;
    icArray[6] = parseInt(icArray[6], 10) * 3;
    icArray[7] = parseInt(icArray[7], 10) * 2;

    var weight = 0;
    for (i = 1; i < 8; i++) {
      weight += icArray[i];
    }

    var offset = (icArray[0] == "G") ? 4 : 0;
    var temp = (offset + weight) % 11;

    var fg = ["X", "W", "U", "T", "R", "Q", "P", "N", "M", "L", "K"];

    var theAlpha;
    if (icArray[0] == "F" || icArray[0] == "G") { theAlpha = fg[temp]; }

    return (icArray[8] === theAlpha);
  }

  var validateNRIC = function (str) {
    if (str.length != 9) {
      return false;
    }

    str = str.toUpperCase();

    var i,
      icArray = [];
    for (i = 0; i < 9; i++) {
      icArray[i] = str.charAt(i);
    }

    icArray[1] = parseInt(icArray[1], 10) * 2;
    icArray[2] = parseInt(icArray[2], 10) * 7;
    icArray[3] = parseInt(icArray[3], 10) * 6;
    icArray[4] = parseInt(icArray[4], 10) * 5;
    icArray[5] = parseInt(icArray[5], 10) * 4;
    icArray[6] = parseInt(icArray[6], 10) * 3;
    icArray[7] = parseInt(icArray[7], 10) * 2;

    var weight = 0;
    for (i = 1; i < 8; i++) {
      weight += icArray[i];
    }

    var offset = (icArray[0] == "T") ? 4 : 0;
    var temp = (offset + weight) % 11;

    var st = ["J", "Z", "I", "H", "G", "F", "E", "D", "C", "B", "A"];

    var theAlpha;
    if (icArray[0] == "S" || icArray[0] == "T") { theAlpha = st[temp]; }

    return (icArray[8] === theAlpha);
  }

  var nric_ClientValidate = function (sender, args) {
    args.IsValid = validateNRIC(args.Value);
  }

  var userConsent_ClientValidate = function (sender, args) {
    var $consentContainer = $(sender).closest(".scfCheckboxListGeneralPanel");
    args.IsValid = ($consentContainer.find("input:checkbox:checked").length == $consentContainer.find("input:checkbox").length);
  }

  var isValidToApplyNewEvent = function () {
    return isNew && !isEmpty(formType);
  }

  var isValidToAddAnotherSpeaker = function (evtCode) {
    if (!isNew && !isEmpty(formType)) {
      return !isEmpty(evtCode);
    }
    return false;
  }

  var moveToStep = function (value) {

    $('#StepHidden').val(value);
    render(value);

  }

  var init = function () {
    var $_GET = {},
      args = location.search.substr(1).split(/&/);

    for (var i = 0; i < args.length; ++i) {
      var tmp = args[i].split(/=/);
      if (tmp[0] != "") {
        var keyProp = decodeURIComponent(tmp[0]).toLowerCase();
        $_GET[keyProp] = decodeURIComponent(tmp.slice(1).join("").replace("+", " "));
      }
    }

    $('#formTypeRadio :input').on('click', function (e) {
      setFormType($(this).val());
    });
    $('#hasEventCodeRadio :input').on('click', function (e) {
      setIsNew($(this).val() !== 'Y');
    });
    $('.nationality :input').on('click', function (e) {
      var $this = $(this),
        value = $this.val();
      reveal($this, value === 'citizen' ? 0 : value === 'pr' ? 1 : 2);
    });
    $('.organisation-details :input').on('click', function (e) {
      var $this = $(this),
        value = $this.val();
      revealForeignEntity($this, value !== 'local');
    });
    $('.structure-setup :input').on('click', function (e) {
      showStructureFileUpload($(this).val() === 'Y');
    });
    $('.scf-event-date, .scf-event-starttime').on('change', function (e) {
      validateStartDate(this);
    });
    $('.scf-event-endtime').on('change', function (e) {
      validateEndTime(this);
    });

    if ($_GET.eventid !== undefined) {
      $("#hasEventCodeRadio input[value='Y']").attr('checked', 'checked');
      $("#eventCodeTextBox").val($_GET.eventid);
    }

    setFormType($("#formTypeRadio :checked").val());
    setIsNew($("#hasEventCodeRadio :checked").val() !== "Y");
    render(parseInt($('#StepHidden').val()) || 0);
  }

  parent.SpeakersCorner = {
    nric_ClientValidate: nric_ClientValidate,
    startTimeValidator_ClientValidate: startTimeValidator_ClientValidate,
    duration_ClientValidate: duration_ClientValidate,
    isPerformanceExhibitionCheck: isPerformanceExhibitionCheck,
    isValidToApplyNewEvent: isValidToApplyNewEvent,
    isValidToAddAnotherSpeaker: isValidToAddAnotherSpeaker,
    userConsent_ClientValidate: userConsent_ClientValidate,
    moveToStep: moveToStep,
    init: init
  }

  return parent;

}(window.RR || {}, jQuery));;