/**
 * RR - Tabs Toggle
 */
window.RR = (function(parent, $) {
    'use strict';

    var setup = function($tabs){

        if(!$tabs.length) return;

        $tabs.each(function(){
            create($(this));
        });

    };

    var create = function($obj){
        var $classes = $obj.data('tab-classes').trim().split(" "),
            $tabAnchors = $obj.find('a'),
            $tabObjs = [],
            slideCounter = false;

        var setSlideCounterFalse = function() {
            slideCounter = false;
        };

        for(var i = 0; i < $classes.length; i++){
            var $tagObj = $('.'+$classes[i]);

            // Display the first object
            if(i !== 0 ) {
                $tagObj.hide();
            } else {
                $tagObj.show();
            }

            $tabObjs.push($tagObj);
        }

        $tabAnchors.off().on('click', function(e){
            var $this   = $(this),
                idx     = $this.index();

            if(!slideCounter){

                $tabAnchors.removeClass();
                $this.addClass('active');
                if(idx === 1) {
                    $this.parent().parent().addClass('list-view');
                } else {
                    $this.parent().parent().removeClass('list-view');
                }

                slideCounter = true;

                for(var i = 0; i < $tabObjs.length; i++) {
                    $tabObjs[i].stop();

                    // Fall back to show/hide in IE8 because the sliding cause floating layout issues
                    if( !$('.lt-ie9').length ) {
                        if(i !== idx) {
                            $tabObjs[i].stop().slideUp(setSlideCounterFalse);
                        } else {
                            $tabObjs[i].stop().slideDown(setSlideCounterFalse);
                        }
                    } else {
                        if(i !== idx) {
                            $tabObjs[i].hide();
                        } else {
                            $tabObjs[i].show();
                        }
                        slideCounter = false;
                    }
                }
            }

            e.preventDefault();
        });

        $tabAnchors.eq(0).addClass('active');
    };

    parent.tabifier = {
        setup: setup
    };

    return parent;

}(window.RR || {}, jQuery));