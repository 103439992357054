window.RR = (function (parent, $) {
  'use strict';

  let $siteFooter = $('.dss-site-footer'),
    $subscribeModule = $('.site-footer__subscribe', $siteFooter),
    $emailInput = $('.subscribe__email-input', $subscribeModule),
    $subscribeForm = $('.subscribe__form', $subscribeModule),
    $subscribeMessage = $('.subscribe__message', $subscribeModule),
    ajaxSubscriptionUrl = $siteFooter.data('subscriptionURL');

  //Check validation of email input
  function isValidEmail(emailInput) {
    var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
    return regex.test(emailInput);
  }

  function postSubscriptionRequest(emailInput) {
    $.ajax({
      url: ajaxSubscriptionUrl,
      method: 'POST',
      contentType: 'application/json',
      dataType: 'json',
      data: JSON.stringify({
        subscriberEmail: emailInput
      }),
      ifModified: true
    })
      .done(function (response) {
        if (response.Status === 200) {
          //Reset subscribe module and change message to reflect success
          $emailInput.val('');
          //Show sucess message from api
          $subscribeMessage.text(response.Message);
        }
      })
      .fail(function (errorMsg) {
        console.log(errorMsg);
        //Show failure message
        $subscribeMessage.text(errorMsg.Message);
      });
  }

  var allDevices = function () {
    //On subscribe email input enter, simulate subscribe submit button click
    //Webform backend limitation
    $('#ctl14_ctl04_Subscribe_field_FFF81200AAD348FAAFE22EF8F166F429').on('keydown', function (e) {
      if (e.which == 13) {
        $('#ctl14_ctl04_Subscribe_form_EA2C2AEC277D42F781E9851F25D91E5D_submit').click();
      }
    });
    $subscribeForm.submit(function (event) {
      event.preventDefault();
      const emailInput = $emailInput.val();
      if (isValidEmail(emailInput)) {
        //Send API request
        postSubscriptionRequest(emailInput);
      } else {
        //Show error message
        $subscribeMessage.text($subscribeModule.data('invalidMessage'));
      }
    });
  };

  allDevices();

  parent.dssSiteFooter = {};

  return parent;
})(window.RR || {}, jQuery);